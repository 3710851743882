import IconTwitter from '../images/twitter-logo.svg'
import IconDiscord from '../images/discord-logo.svg'
import {HStack, VStack, Text, Flex} from '@chakra-ui/react';

export default function Social({direction}) {
    const dir = direction

    return (
        <>
        {   dir === "row" ? 
                <HStack mx={4}>
                    <a href="https://twitter.com/LongGameWTF" target="_blank" rel="noopener noreferrer">
                        <img src={IconTwitter} alt="Twitter" />
                    </a>
                    <a href="https://discord.gg/WjVMH34ZF7" target="_blank" rel="noopener noreferrer">
                        <img src={IconDiscord} alt="Discord" color="brand.700"/>
                    </a>
                </HStack>
                :
                <Flex direction="column">
                    <Flex as="a" href="https://twitter.com/LongGameWTF" target="_blank" rel="noopener noreferrer" alignItems="center">
                        <img as="span" src={IconTwitter} alt="Twitter" />
                        <Text display="inline" color="brand.700" mx={2} fontSize="md">Twitter</Text>
                    </Flex>
                    <Flex as="a" href="https://discord.gg/WjVMH34ZF7" target="_blank" rel="noopener noreferrer" alignItems="center" mt={10}>
                        <img as="span" src={IconDiscord} alt="Discord"/>
                        <Text display="inline" color="brand.700" mx={2} fontSize="md">Discord</Text>
                    </Flex>
                </Flex>
        }
         </>

    );
}