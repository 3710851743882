import { Box, HStack, Flex, Text, useMediaQuery, Image, VStack, Divider, Center,
    Table, Thead, Tbody, Tr, Th, Td

} from "@chakra-ui/react";
import { CloseIcon, InfoIcon } from '@chakra-ui/icons'
import {motion} from "framer-motion"
import { useAccount } from 'wagmi';
import { Backdrop } from "./Backdrop"
import { formatAvatarUrl } from '../utils/utils';
import { entryFee, payoutRate, textShadowValue } from "../utils/constant";
import { RoundStatus } from "./Activities";
import { useNativeCurrency } from '../providers/NativeCurrencyProvider'; // Adjust the import path as needed
import { GameTooltip } from "./GameTooltip"

export function HistoryModal({handlClose, roundInfos, totalGameStats}) {

    const headers = ["Past Round", "Spent", "Earned", "Status"]
    const totalStatsHeader = ["Total Rounds", "Total Spent", "Total Earned", "$LONG CLAIMED"]
    
    const { address } = useAccount();
    const nativeCurrencySymbol = useNativeCurrency();
    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
    const dropIn = {
        hidden: {
            y: "100vh"
        },
        visible: {
            y: "0",
            transition: {
                duration: 2,
                type: "spring",
                damping: 88,
                stiffness: 500,
            },
            zIndex: 1000
        },
        exit: {
            y:" 100vh"
        }
    }

    const roundStatusText = (roundStatus) => {
        if(RoundStatus.EXITED === roundStatus) { 
            return (
                <Text fontSize={{base: "xs", md: "sm", xl: "md"}}  color="brand.100" >
                            {RoundStatus.EXITED}              
                </Text>
            )
        }
        if(RoundStatus.LONG_ELIGIBLE === roundStatus) { 
            return (
                <Text fontSize={{base: "xs", md: "sm", xl: "md"}}  color="brand.200" >
                            {RoundStatus.LONG_ELIGIBLE}              
                </Text>

            )
        }
        if(RoundStatus.CLIAMED === roundStatus) { 
            return (
                <Text fontSize={{base: "xs", md: "sm", xl: "md"}}  color="brand.1000" >
                            {RoundStatus.CLIAMED}              
                </Text>

            )
        }
    }

    const showFooter = () => {
        return (
            <GameTooltip 
                message="Note: The history section displays past rounds only. Please visit the main page for the current round status. ">  
                <InfoIcon w={4} h={4} color="brand.700"/>
            </GameTooltip>
        )
    }

    const showTotalStats = () => {
        return (
            <VStack width="100%" overflowX="auto" overflowY="auto"  mt={2}>
               
               <Table variant="unstyled">
                <Thead>
                        <Tr>
                        {totalStatsHeader.map((t, index) => (
                            <Th key={index} position="sticky" top="0" bg="brand.800">
                            <Text fontSize={["xs", "sm"]} fontWeight="semibold" color="brand.700">
                                {t}
                            </Text>
                            </Th>

                        ))}
                        </Tr>

                </Thead>
                    <Tbody>
                    
                        <Tr  direction="row" justify="space-between" align="center" width="100%" >
                            <Td>
                            <Text color="brand.100" fontSize={{base: "xs", md: "sm", xl: "md"}}> 
                                {totalGameStats.totalRounds}  
                            </Text>

                            </Td>
                            <Td>
                                <Text color="brand.100" fontSize={{base: "xs", md: "sm", xl: "md"}}>
                                {totalGameStats.totalSpent.toFixed(3)} {nativeCurrencySymbol} 
                                </Text>
                            </Td>
                            <Td>
                                <Text color="brand.100" fontSize={{base: "xs", md: "sm", xl: "md"}}>
                                {totalGameStats.totalEarned.toFixed(3)} {nativeCurrencySymbol} 
                                </Text>
                            </Td>
                            <Td>
                            <Text color="brand.100" fontSize={{base: "xs", md: "sm", xl: "md"}}> 
                            {totalGameStats.totalClaimed}  
                            </Text>
                                
                            </Td>
                        </Tr>
                            
                    </Tbody>
                
                </Table> 
                </VStack>
        )
    }

    const populateTalbeContent = () => {
        return (
            <VStack width="100%" overflowX="auto" overflowY="auto" minHeight="200px" mt={2}>
               
               <Table variant="unstyled">
                <Thead>
                        <Tr>
                        {headers.map((t, index) => (
                            <Th key={index} position="sticky" top="0" bg="brand.800">
                            <Text fontSize={["xs", "sm"]} fontWeight="semibold" color="brand.700">
                                {t}
                            </Text>
                            </Th>

                        ))}
                        </Tr>

                </Thead>

                {roundInfos && roundInfos.length > 0 && 
                    <Tbody>
                        { roundInfos.map(( history, index) => (
                                <Tr key={index} direction="row" justify="space-between" align="center" width="100%" >
                                    <Td>
                                        <HStack>
                                            <Image src={formatAvatarUrl(address)} boxSize="24px" />
                                            <Text fontSize={{base: "xs", md: "sm", xl: "md"}} color="brand.100" > Round {history.roundId} </Text>
                                        </HStack>

                                    </Td>
                                    <Td>
                                        <Text  fontSize={{base: "xs", md: "sm", xl: "md"}}  color="brand.500" > 
                                            {(history.totalPositionCnt * entryFee).toFixed(3)} {nativeCurrencySymbol}
                                        </Text>
                                    </Td>
                                    <Td>
                                        <Text fontSize={{base: "xs", md: "sm", xl: "md"}}  color="brand.500" > 
                                            {(history.exitedPositionCnt * payoutRate * entryFee).toFixed(3)} {nativeCurrencySymbol} </Text>
                                    </Td>
                                    <Td>
                                        {roundStatusText(history.roundStatus)}
                                        
                                    </Td>
                                </Tr>
                            )) }
                    </Tbody>
                }
                </Table> 

                {!roundInfos || roundInfos.length === 0 && 
                
                <Center>
                    <Text fontSize={{base: "sm", md: "md"}} color="brand.700" fontWeight="semibold" mt={6}>
                        No Activities 
                    </Text>
                </Center>
                }
                
           
        </VStack>
        )
    }
    /*clipPath={{ 
        base: "polygon(10% 0, 100% 0%, 100% 93%, 90% 100%, 0 100%, 0 7%)",
        md: "polygon(10% 0, 100% 0%, 100% 93%, 90% 100%, 0 100%, 0 7%)",
        xl: "polygon(10% 0, 100% 0%, 100% 80%, 90% 100%, 0 100%, 0 20%)"
    }}*/ 

    return (


        <Box>
            { isLargerThan768? 
            <Backdrop onClick={handlClose}>
            <Center>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={dropIn}
            >
                <Flex 
                    direction="column" 
                    height={{base:"100vh", md:"100%" }}
                    
                    width={{base: "100vw", md: "700px", xl: "1000px"}} 
                    position="relative"
                    justifyContent="center" 
                    align="center" mt="10%"
                    backgroundColor="brand.800"
                    p={6}
                >
                     <CloseIcon onClick={handlClose}
                        boxSize="12px" color="brand.700" position="absolute" top="5%" right="5%"/>
                    <Flex direction="row" align="center" >
                        <Text fontWeight="semibold" fontSize={{base: "md", md: "xl", xl: "2xl"}} color="brand.100" 
                                textShadow={textShadowValue}
                                px={4}
                        >
                            History
                        </Text>
                        {showFooter()}
                    </Flex>

                <Divider borderColor="brand.700" width="95%" height="24px" />
                 {populateTalbeContent()}
                 <Divider borderColor="brand.700" width="95%" height="24px" />
                 {totalGameStats && roundInfos && roundInfos.length > 0 && 
                  showTotalStats() 
                }
                 
                </Flex>
                
            </motion.div>
            </Center>
        </Backdrop>:
        <motion.div
                onClick={handlClose}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={dropIn}
                style={{ width: "100vw", height: "100vh", position: "fixed", bottom: 0, left: 0, backgroundColor: "brand.800", zIndex: 200 }}
            >
                <CloseIcon onClick={handlClose}
                        boxSize="12px" color="brand.700" position="absolute" top="5" right="5"/>
                <Flex 
                    direction="column" 
                    justifyContent="center" 
                    align="center" 
                    backgroundColor="brand.800"
                    height="100vh"
                    pt={6} 
                >
                    <Flex direction="row" align="center" justify="center">
                        <Text fontWeight="semibold" fontSize={{base: "lg", md: "xl"}} color="brand.100" 
                                textShadow={textShadowValue}
                        >
                            History
                        </Text>
                        {showFooter()}
                    </Flex>
                    <Divider borderColor="brand.700" width="95%"  height="24px"  />
                    {populateTalbeContent()}
                    <Divider borderColor="brand.700" width="95%" />
                    {totalGameStats && roundInfos && roundInfos.length > 0 && 
                            showTotalStats() 
                    }
                    </Flex>
                    
            </motion.div>
}
        </Box>
        
    )

}