import { Flex, Text, Table, Thead, Tbody, Tr, Th, Td, TableCaption, Box, Image, Skeleton } from "@chakra-ui/react";
import { Tab, Tabs, TabList, TabPanel, TabPanels} from "@chakra-ui/react";
import { useQuery, gql } from '@apollo/client';
import { backendClinet, graphQLClient } from '../graphql/apollo-client';
import { useState, useEffect } from 'react'
import { useAccount } from 'wagmi';
import {formatAddress, formatAvatarUrl} from "../utils/utils"
import { entryFee, textShadowValue } from "../utils/constant"
import { useNativeCurrency } from '../providers/NativeCurrencyProvider'; 
import { ethers } from 'ethers';
import { formatDate } from "date-fns";
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'
import { FaHandPointLeft } from "react-icons/fa";
import { LongGameID } from "../utils/constant";
import { formatDistanceToNow } from 'date-fns';


/**
{
  "data": {
    "userStats": {
      "items": [
        {
          "id": "0xcfc46C05deccc4518d853EffaA1b994BddC0cF02",
          "joinedAt": 1717011420,
          "rounds": [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            11
          ],
          "totalExitedPositions": 6,
          "totalLong": "50000000000000000000000",
          "totalPositions": 29
        },
 */

const GET_USER_STATS = gql`
query GetUserStats($col: String) {
    userStats(orderBy: $col, limit: 100, orderDirection: "desc") {
      items {
        id
        joinedAt
        rounds
        totalExitedPositions
        totalLong
        totalPositions
      }
    }
  }
`

const GET_REFERRAL_STATS = gql`
query GetReferralStats {
    referralStats(orderBy: "positionCount", orderDirection: "desc") {
        items {
          id
          lastJoinedAt
          participant
          positionCount
        }
    }
  }
`

const GET_USER_CODE = gql`
query GetUserCode( $userId: ID!, $appId: ID!) {
    getUserReferralCode(userId: $userId, appId: $appId)
  }
`

export function LeaderBoard() {

    const { address } = useAccount();
    const nativeCurrencySymbol = useNativeCurrency();
    // total rounds, total spent, total earned, total claimed
    const [ leaderboard, setLeaderboard] = useState([])
    
    // referral stats
    const [referralStats, setReferralStats] = useState([])

    // current user code
    const [myCode, setMyCode] = useState(null)

    const {loading, error, data} = useQuery(GET_USER_STATS, {
        variables: {col: "totalExitedPositions"},
        client: graphQLClient,
    })

    const {loading: referralLoad, error: referralError, data: referralData} = useQuery(GET_REFERRAL_STATS, {
        client: graphQLClient,
    })

    const {loading: codeLoad, error: codeError, data: codeData} = useQuery(GET_USER_CODE, {
        variables: {userId: address, appId: LongGameID},
        client: backendClinet,
    })

    const headers = ["Rank", "Name", "Earned", "$LONG", "Since"]
    const referralHeaders = ['Rank', 'Referral Code', 'Total Referred', 'Last Joined By', 'Last Joined']

    useEffect(() => {
        if (codeData && codeData.getUserReferralCode && codeData.getUserReferralCode.length > 0 ) {
            setMyCode(codeData.getUserReferralCode)
        }
    }, [codeData]);

    useEffect(() => {
        if (data && data.userStats && data.userStats.items && data.userStats.items.length > 0 ) {
            const rankedList = data.userStats.items.map((usr, index) => {
                return {
                    rank: index+1,
                    userAddress: usr.id,
                    earned: usr.totalExitedPositions * entryFee * 2,
                    longToken: parseFloat(ethers.utils.formatEther(usr.totalLong)).toLocaleString(),
                    memberSince: usr.joinedAt
                }
            })
            setLeaderboard(rankedList)
        }
      }, [data]);

    useEffect(() => {
        if (referralData && referralData.referralStats && referralData.referralStats.items && referralData.referralStats.items.length > 0 ) {
            const filterNone = referralData.referralStats.items.filter(item => item.id !== "");
            const rankedList = filterNone.map((referral, index) => {
               
                    return {
                        rank: index+1,
                        code: referral.id,
                        positionCount: referral.positionCount, 
                        lastReferred: referral.participant,
                        lastJoinedAt: referral.lastJoinedAt,
                    }
                
                
            })
            setReferralStats(rankedList)
           
        }
    }, [referralData]);


    const populateReferralContent = () => {
        return (
            <Box width="100%" overflowX="auto" overflowY="auto"  mt={2} height="70vh">
                <Table variant="unstyled" size={{base:"sm", md: "md"}} >
                    <TableCaption>
                        <Text align="right" fontSize="xs" color="brand.700"> *Please Refresh for latest stats</Text>
                    </TableCaption>
                        <Thead>
                                <Tr>
                                {referralHeaders.map((t, index) => (
                                    <Th key={index}  bg="brand.800">
                                        <Text align={index === 0? "center": "left"} fontSize={["xs", "sm"]} fontWeight="semibold" color="brand.700">
                                            {t}
                                        </Text>
                                    
                                    </Th>
                                ))}
                                </Tr>
                        </Thead>
                        <Tbody>
                        {
                            referralStats.map((referral, index) => (
                                <Tr  key={index} direction="row"  width="100%" 
                                    border={myCode === referral.code? "1px": "0"}
                                    borderColor={myCode === referral.code? "brand.100": "brand.800"}
                                >
                                    <Td>
                                        <Text align="center" color="brand.100" fontWeight="bold" textShadow={textShadowValue} fontSize={{base: "xs", md: "sm", xl: "md"}}> 
                                            {referral.rank}  
                                        </Text>
                                    </Td>
                                    <Td>
                                        <Text color="brand.100" fontSize={{base: "xs", md: "sm", xl: "md"}}>
                                        {referral.code}  
                                        </Text>
                                    </Td>
                                    <Td >
                                        <Text color="brand.100" fontSize={{base: "xs", md: "sm", xl: "md"}}>
                                        {referral.positionCount} 
                                        </Text>
                                    </Td>
                                    <Td>
                                        <Flex direction={{base: "column", md:"row"}} >
                                            
                                            <Text color="brand.100" fontSize={{base: "xs", md: "sm", xl: "md"}}> 
                                                {formatAddress(referral.lastReferred)}  
                                                
                                            </Text >
                                        </Flex>
                                    </Td>
                                    

                                    <Td>
                                    <Text color="brand.100" fontSize={{base: "xs", md: "sm", xl: "md"}}> 
                                    {formatDistanceToNow(new Date(Math.floor(referral.lastJoinedAt*1000)), { addSuffix: true })}
                                    </Text>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                </Table> 
            </Box>
        )
    }

    const populateEarnedContent = () => {
        return (
                    <Box width="100%" overflowX="auto" overflowY="auto"  mt={2} height="70vh">
                        <Table variant="unstyled" size={{base:"sm", md: "md"}} >
                            <TableCaption>
                                <Text align="right" fontSize="xs" color="brand.700"> *Leaderborad refreshes every 1 min</Text>
                            </TableCaption>
                            <Thead>
                                    <Tr>
                                    {headers.map((t, index) => (
                                        <Th key={index}  bg="brand.800">
                                            <Text align={index === 0? "center": "left"} fontSize={["xs", "sm"]} fontWeight="semibold" color="brand.700">
                                                {t}
                                            </Text>
                                        
                                        </Th>
                                    ))}
                                    </Tr>
                            </Thead>
                            <Tbody>
                            {
                                leaderboard.map((d, index) => (
                                    <Tr  key={index} direction="row"  width="100%" 
                                        border={address === d.userAddress? "1px": "0"}
                                        borderColor={address === d.userAddress? "brand.100": "brand.800"}
                                    >
                                        <Td>
                                            <Text align="center" color="brand.100" fontWeight="bold" textShadow={textShadowValue} fontSize={{base: "xs", md: "sm", xl: "md"}}> 
                                                {d.rank}  
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Flex direction={{base: "column", md:"row"}} >
                                                <Image src={formatAvatarUrl(d.userAddress)} boxSize="24px" mr={4} />
                                                <Text color="brand.100" fontSize={{base: "xs", md: "sm", xl: "md"}}> 
                                                    {formatAddress(d.userAddress)}  
                                                </Text >
                                                
                                                { address === d.userAddress && 
                                                <Box ml="2" >
                                                    <FaHandPointLeft size="20px"  color="#ACFF01"/>
                                                </Box>

                                                }
                                            </Flex>
                                        </Td>
                                        <Td >
                                            <Text color="brand.100" fontSize={{base: "xs", md: "sm", xl: "md"}}>
                                            {d.earned} {nativeCurrencySymbol} 
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text color="brand.100" fontSize={{base: "xs", md: "sm", xl: "md"}}>
                                            {d.longToken}  
                                            </Text>
                                        </Td>
                                        <Td>
                                        <Text color="brand.100" fontSize={{base: "xs", md: "sm", xl: "md"}}> 
                                        {formatDate(new Date(d.memberSince * 1000), "MM/dd/yyyy")}  
                                        </Text>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table> 
                    </Box>
        )
    }

    return(
        <Flex  direction="column" justifyContent="center" alignContent="center"
            p={{base:"16px", md: 6}}
            mt={4}
            mx={2}
            width="100%"
            bgColor="brand.800"
        > 
        <Flex direction={{base:"column", md: "row"}} justify={{base:"center", md: "space-between"}} alignItems="baseline">
            <Text fontSize={{base:"lg", md:"xl", xl: "2xl"}} color="brand.100" fontWeight="bold" textShadow={textShadowValue}>
                Leaderboard
            </Text>      
            <Text fontSize={{base:"xs", md:"sm", xl: "md"}} color="brand.700" fontWeight="semibold">
                <ChakraLink as={ReactRouterLink} to='/playtestnet' color="brand.100">
                    Play Long Game {' '}
                </ChakraLink>
                
                Earn 2x of what you put in, or Grow rich with $Long Game
                
                    
            </Text>       
        </Flex>  
       
            {/*  <Divider borderColor="brand.700" width="100%" height="24px" /> */ }
        <Tabs isFitted isLazy variant='enclosed' size="md" mt={6}>
            <TabList >
                <Tab color="brand.700" > Earned</Tab>
                <Tab color="brand.700" >Referrals</Tab>
                
             </TabList>
            
            <TabPanels>
            <TabPanel>
                <Skeleton isLoaded={!loading} minHeight="70vh" startColor='brand.400' endColor='brand.400'>
                    {error && <Text color="brand.700">Error fetching leaderboard...</Text>}
                    {leaderboard?.length > 0 && 
                    populateEarnedContent()
                }
                </Skeleton>  
            </TabPanel>
                
            <TabPanel>
                <Skeleton isLoaded={!referralLoad} minHeight="70vh" startColor='brand.400' endColor='brand.400'>
                {referralError && <Text color="brand.700">Error fetching referral stats...</Text>}
                {referralStats?.length > 0 &&  populateReferralContent() }
                </Skeleton>        
            </TabPanel>
                

            </TabPanels>
        </Tabs>
        </Flex>
    );
}