import { Box, HStack, Flex, Text, useMediaQuery, Image, VStack, Divider, Center,
    Table, Thead, Tbody, Tr, Th, Td

} from "@chakra-ui/react";
import { CloseIcon } from '@chakra-ui/icons'
import {motion} from "framer-motion"
import { Backdrop } from "./Backdrop"
import { formatAvatarUrl, formatAddress } from '../utils/utils';
import { textShadowValue } from "../utils/constant";
import { formatDistanceToNow } from 'date-fns';
export function ReferralModal({handlClose, referrals}) {

    const headers = ["Referrals", "Positions", "Joined Time"]
    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
    const dropIn = {
        hidden: {
            y: "100vh"
        },
        visible: {
            y: "0",
            transition: {
                duration: 2,
                type: "spring",
                damping: 88,
                stiffness: 500,
            },
            zIndex: 1000
        },
        exit: {
            y:" 100vh"
        }
    }


    const populateTalbeContent = () => {
        return (
            <VStack width="100%" overflowX="auto" overflowY="auto" minHeight="200px" mt={2}>
               
               <Table variant="unstyled">
                <Thead>
                        <Tr>
                        {headers.map((t, index) => (
                            <Th key={index} position="sticky" top="0" bg="brand.800">
                            <Text fontSize={["xs", "sm"]} fontWeight="semibold" color="brand.700">
                                {t}
                            </Text>
                            </Th>

                        ))}
                        </Tr>

                </Thead>

                {referrals && referrals.length > 0 && 
                    <Tbody>
                        { referrals.map(( referral, index) => (
                                <Tr key={index} direction="row" justify="space-between" align="center" width="100%" >
                                    <Td>
                                        <HStack>
                                            <Image src={formatAvatarUrl(referral.participant)} boxSize="24px" />
                                            <Text fontSize={{base: "xs", md: "sm", xl: "md"}} color="brand.100" > {formatAddress(referral.participant)} </Text>
                                        </HStack>

                                    </Td>
                                    <Td>
                                        <Text  fontSize={{base: "xs", md: "sm", xl: "md"}}  color="brand.500" > 
                                            {referral.joinCount}
                                        </Text>
                                    </Td>
                                    <Td>
                                        <Text fontSize={{base: "xs", md: "sm", xl: "md"}}  color="brand.500" > 
                                        {formatDistanceToNow(new Date(Math.floor(referral.joinedAt*1000)), { addSuffix: true })}
                                        </Text>
                                    </Td>
                                </Tr>
                            )) }
                    </Tbody>
                }
                </Table> 

                {!referrals || referrals.length === 0 && 
                
                <Center>
                    <Text fontSize={{base: "sm", md: "md"}} color="brand.700" fontWeight="semibold" mt={6}>
                        No Referrals 
                    </Text>
                </Center>
                }
                
           
        </VStack>
        )
    }

    return (
        <Box>
            { isLargerThan768? 
            <Backdrop onClick={handlClose}>
            <Center>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={dropIn}
            >
                <Flex 
                    direction="column" 
                    height={{base:"100vh", md:"100%" }}
                    
                    width={{base: "100vw", md: "700px", xl: "1000px"}} 
                    position="relative"
                    justifyContent="center" 
                    align="center" mt="10%"
                    backgroundColor="brand.800"
                    p={6}
                >
                     <CloseIcon onClick={handlClose}
                        boxSize="12px" color="brand.700" position="absolute" top="5%" right="5%"/>
                    <Flex direction="row" align="center" >
                        <Text fontWeight="semibold" fontSize={{base: "md", md: "xl", xl: "2xl"}} color="brand.100" 
                                textShadow={textShadowValue}
                                px={4}
                        >
                            Referrals
                        </Text>
                      
                    </Flex>

                <Divider borderColor="brand.700" width="95%" height="24px" />
                    {populateTalbeContent()}
                </Flex>
                
            </motion.div>
            </Center>
        </Backdrop>:
        <motion.div
                onClick={handlClose}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={dropIn}
                style={{ width: "100vw", height: "100vh", position: "fixed", bottom: 0, left: 0, backgroundColor: "brand.800", zIndex: 200 }}
            >
                <CloseIcon onClick={handlClose}
                        boxSize="12px" color="brand.700" position="absolute" top="5" right="5"/>
                <Flex 
                    direction="column" 
                    justifyContent="center" 
                    align="center" 
                    backgroundColor="brand.800"
                    height="100vh"
                    pt={6} 
                >
                    <Flex direction="row" align="center" justify="center">
                        <Text fontWeight="semibold" fontSize={{base: "lg", md: "xl"}} color="brand.100" 
                                textShadow={textShadowValue}
                        >
                            Referrals
                        </Text>
                        
                    </Flex>
                    <Divider borderColor="brand.700" width="95%"  height="24px"  />
                    {populateTalbeContent()}
                    </Flex>
                    
            </motion.div>
}
        </Box>
        
    )

}