import { VStack, Box } from "@chakra-ui/react";
import { AboutLongGame } from "./Components/AboutLongGame";
import { OurTeam } from "./Components/OurTeam";
import { Offer } from "./Components/Offer";
import { ActionFooter } from "./Components/ActionFooter";
import Menu from "./Components/Menu"

export function About() {
    return(
        <VStack width="100%" overflow= 'hidden'>
            <AboutLongGame />
             <Offer />
             <OurTeam />
            <ActionFooter />
        </VStack>
    );
}