import { VStack, Box } from "@chakra-ui/react";

import Menu from "./Components/Menu";
import { Game } from "./Game/Game";

export function Launch() {
    return(
        <VStack width="100%" overflow= 'hidden'>
            <Game />
        </VStack>
    );
}