import { Flex, Text, Button, HStack, Image, Box, Skeleton } from "@chakra-ui/react";
import {Input, InputRightElement, InputLeftElement, InputGroup } from "@chakra-ui/react";
import { InfoIcon } from '@chakra-ui/icons'
import { formatAddress } from "../utils/utils";
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { graphQLClient, backendClinet } from '../graphql/apollo-client';
import { networkInfo } from "../utils/config";
import { useNetwork, useSwitchNetwork } from 'wagmi';
import { MdOutlineErrorOutline } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { parseQueryString} from '../utils/utils'
import { LongGameID } from "../utils/constant";
import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    Tooltip
  } from '@chakra-ui/react'
import { useState, useEffect, useRef } from 'react'
import ETHIcon from '../images/eth.svg'
import { entryFee } from "../utils/constant";
import { textShadowValue } from "../utils/constant"
import { useNativeCurrency } from '../providers/NativeCurrencyProvider'; 
import { AnimatedCounter } from "../AnimateComponents/AnimatedCounter"
import { GameTooltip } from "./GameTooltip"


const GET_USER_DATA = gql`
query GetUserQuery($id: String!) {
    user(id: $id){
            id
            lastJoinedAt
            positions
            roundId
            totalPositionCnt
            createdAt
    }
}
`;

const GET_REFERRAL_CODE = gql`
query GetUserForReferralCode($referralCode: String!, $appId: ID!) {
    getUserForReferralCode(referralCode: $referralCode, appId: $appId)
}
`;

export function JoinGame({address, joinGame, currentRound, joining, joinTxInfo, isGameEnded}) {
    // wallet state
    const { chain } = useNetwork();
    const [isCorrectNetwork, setIsCorrectNetwork] = useState(chain?.id === networkInfo.chain.id);
    
    const [sliderValue, setSliderValue] = useState(5)
    const [showTooltip, setShowTooltip] = useState(false)
    const [count, setCount] = useState(5)

    const [acitveInRound, setActiveInRound] = useState(false)
    const [totalPosition, setTotalPosition] = useState(0)
    // referral code state
    const [referralCode, setReferralCode] = useState('');
    const [isValid, setIsValid] = useState(true);

    const nativeCurrencySymbol = useNativeCurrency();
    const prevPositionCntRef = useRef();

    // the issue here is when immediately user join, the fetch didn't updated the db with event yet.
    const {loading, error, data} = useQuery(GET_USER_DATA, {
        variables: { id: address+"+"+currentRound },
        pollInterval: 10000 , // Poll every 10 seconds
        client: graphQLClient
    })

    const { data: referralData , loading: referralLoading, error: referralError } = useQuery(GET_REFERRAL_CODE, {
        variables: { referralCode: referralCode, appId: LongGameID },
        skip: referralCode.length !== 5,
        onCompleted: data => {
          // Assuming the API returns true or false for validity
          console.log("referral request: data=", data)
          setIsValid(data?.getUserForReferralCode);
        }
      });

    // Fetch contract info
    useEffect(() => { 
        setIsCorrectNetwork(chain?.id === networkInfo.chain.id)
    }, [chain])

    useEffect(() => {
    const fetched = data 
    if (!isGameEnded && fetched && fetched.user ){
        setActiveInRound(true)
        setTotalPosition(fetched.user.totalPositionCnt)
        prevPositionCntRef.current = fetched.user.totalPositionCnt
    }
    }, [data, isGameEnded]);

    useEffect(() => {
        // Reset validity when the user changes the code
        if (referralCode.length < 5) {
          setIsValid(true);
        } else if (referralCode.length > 5) {
            setIsValid(false);
        }
    }, [referralCode]);

    // Effect to parse the hash for referral code
    useEffect(() => {
        const hash = window.location.hash; // Gets the entire hash string
        const queryStringStart = hash.indexOf('?');
        if (queryStringStart !== -1) {
        const queryString = hash.substring(queryStringStart + 1);
        const queryParams = parseQueryString(queryString);
        const referralParam = queryParams['referral'];
        if (referralParam && referralParam.length === 5) {
            setReferralCode(referralParam);
            
        }
        }
    }, []);

    const prevTotalPositionCnt = prevPositionCntRef.current;

    const joinGameText = () => {
        if(isCorrectNetwork && address) {
            return "PLAY NOW"
        } else if(isCorrectNetwork && !address) {
            return "CONNECT WALLET TO PLAY"
        } else if (!isCorrectNetwork && address) {
            return "SWTICH NETWORK TO PLAY"
        } else {
            return "CONNECT WALLET TO PLAY"
        }
    }

    // Handle input changes
    const handleInputChange = (e) => {
        const { value } = e.target;
        setReferralCode(value);
    };

    return(
        <Flex direction="column" justifyContent="center" alignContent="center"
            mx={6}
            mt={6}
        >

            <Text fontSize={{base: "md", md: "lg"}} color="brand.100" 
                fontWeight="semibold" alignItems="left"
                textShadow={textShadowValue}
            >
                Play Game
              
                <GameTooltip 
                    message={`Join the game by securing your position in line,  ${entryFee} ${nativeCurrencySymbol} per position` }>  
                    <InfoIcon w={4} h={4} ml={2} color="brand.700"/>
                </GameTooltip>
            </Text>

            {address? 
                <Box mt={6}>
                    <Skeleton isLoaded={!loading} startColor='0x080808e1' endColor='0x080808e1'>
                    <Text as="span" fontSize={{base: "sm", md: "md"}} color="brand.500" fontWeight="semibold" >
                        Hi {' '}
                    </Text>
                    <Text as="span" fontSize={{base: "sm", md: "md"}} color="brand.100" fontWeight="semibold" >
                        {formatAddress(address)}, 
                    </Text>
                    
                        {!isGameEnded && currentRound > 0 ? 
                       
                            acitveInRound ? 
                            <Box as="span">
                                    <Text as="span" fontSize={{base: "sm", md: "md"}} color="brand.500" fontWeight="semibold">
                                        {' '} your current position size:
                                    </Text>
                                    <Box ml={2} as="span" fontWeight="semibold" fontSize={{base: "sm", md: "md"}}  color="brand.100" textShadow={textShadowValue}>
                                    <AnimatedCounter styles={{as:"span"}}
                                        from={prevTotalPositionCnt} 
                                        to={totalPosition } 
                                    />
                                    </Box>
                                <Text color="brand.700" fontSize={{base: "sm", md: "md"}} mt={2}>
                                    Feeling lucky? Secure more spots early for a higher chance to claim that 200% win!
                                </Text>
                            </Box>
                            :
                            <Box as="span">
                                <Text as="span" fontSize={{base: "sm", md: "md"}} color="brand.500" fontWeight="semibold" mt={6}>
                                    {' '} the round {currentRound} is on fire 🔥, join now before others! 
                                </Text> 
                                <Text color="brand.700" fontSize={{base: "sm", md: "md"}} mt={2}>
                                    Start early, boost your chances of winning 200%! Don't miss your shot at doubling up!
                                </Text>
                            </Box>  
                            :
                        <Box as="span">
                            <Text as="span" fontSize={{base: "sm", md: "md"}} color="brand.500" fontWeight="semibold">
                                {' '} join now to start the game!
                            </Text>
                            <Text color="brand.700" fontSize={{base: "sm", md: "md"}} mt={2}>
                                Start early, boost your chances of winning 200%! Don't miss your shot at doubling up!
                            </Text>
                        </Box>
                        
                        }
                    </Skeleton>
                    
                </Box>
                :
                <Text fontSize={{base: "sm", md: "md"}} color="brand.500" fontWeight="semibold" mt={6}>
                    Hi there, please connect your wallet to play
                </Text>
            }

            <InputGroup mt={6} >
                <InputLeftElement pointerEvents='none' color='brand.700'>
                    <FaUserFriends />
                </InputLeftElement>
                <Input variant='filled' focusBorderColor='brand.100' errorBorderColor='brand.900' borderRadius={0} border="1px"
                    placeholder='Referral Code (Optional)' _placeholder={{ opacity: 1, color: 'brand.700', fontSize:"sm" }}
                    size='md' bgColor="brand.400"
                    color="brand.500"  borderColor="transparent"
                    sx={{
                        _hover: {
                          bg: 'brand.800',  // Change to your desired color
                        }
                      }}
                    value={referralCode}
                    onChange={handleInputChange}
                    isInvalid={!isValid}
                />
                <InputRightElement>
                    { referralCode.length >=5?
                    isValid ? 
                        <IoCheckmarkDoneOutline color="#ACFF01"/> : 
                        <HStack color='#E53E3E' minW="130px" height="30px">
                            <Text fontSize="sm"> INVALID</Text>
                            <MdOutlineErrorOutline color='#E53E3E' />
                        </HStack>
                        
                    : <></>
                    }
                </InputRightElement>
            </InputGroup>

            <Slider 
                defaultValue={count} min={1} max={100} step={1} mt={6} 
                onChange={(v) => setSliderValue(v)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onChangeEnd={(val) => setCount(val)}
            >

                <SliderTrack bg='brand.700' height="8px">
                    <SliderFilledTrack bg='brand.100' />
                </SliderTrack>
                    <Tooltip

                        hasArrow
                        bg='brand.100'
                        color='brand.200'
                        fontWeight="semibold"
                        placement='top'
                        isOpen={showTooltip}
                        label={`${sliderValue} position`}
                    >
                        <SliderThumb boxSize={6} background="brand.100">
                            🐲
                        </SliderThumb>
                    </Tooltip>
            </Slider>
            <Flex direction="row" justify="space-between" mt={6}>
                <Text color="brand.500" fontWeight="semibold" fontSize={{base: "sm", md: "md"}}> Total Spend:</Text>
                <HStack>
                    <Text color="brand.500" fontWeight="semibold" fontSize={{base: "sm", md: "md"}}> 
                    {(count * entryFee).toFixed(3)}  {' ' }  {nativeCurrencySymbol}
                    </Text>
                    <Image src={ETHIcon} boxSize="24px" />
                </HStack>
            </Flex>
            <Button  
                onClick={()=>joinGame(count, referralCode)}
                isLoading={joining}
                isDisabled={!address || !isCorrectNetwork}
                loadingText={joinTxInfo}
                spinnerPlacement='start'
                variant="custom" fontSize={{base: "sm", md: "md" , '2xl': "lg"}}
                width="100%"
                margin="auto"
                mt={6}
            >
                {joinGameText()}
            </Button>
            

        </Flex>
    );
}