import { VStack, Flex, Text, Button } from "@chakra-ui/react";

import Bb3 from '../images/header-bg3.png'
import Bb1 from '../images/header-bg1.png'
import { AnimatedBox } from "../AnimateComponents/AnimatedBox";
import { Link } from 'react-router-dom';

export function ActionFooter() {
    return(
  
        <VStack  bgImage={`url(${Bb1})`}  
            width="100%" 
            bgSize="contain" 
            bgPosition="Bottom" 
            backgroundRepeat="no-repeat"
            direction="column"
            height="700px" 
            mt={20}
        >
                <AnimatedBox distance={300}>
                <Flex bgImage={`url(${Bb3})`}  
                    bgPosition="center"
                    backgroundRepeat="no-repeat"
                    bgSize="auto"
                    direction="column" 
                    justifyContent="space-between"
                    height="514px"
                    width="100%"
                >
                <Flex direction="column" justifyContent="space-between" alignItems="center"  
                    width={{base: "90%", lg: "60%"}} m="auto">
                    <Text color="brand.100" fontWeight="semibold"fontSize={{base:"2xl", md: "4xl"}}> Why ‘Long’ Game?</Text>
                    <Text color="brand.300" fontSize={{base:"md", md: "lg"}}   pt={10} >
                    A wise man once said: We all have two lives, and the second begins when we realize we only have one." 
                    In the spirit of this profound insight, Long Game merges the excitement of play with the strategy of investment.
                    One Life, Play One!

                    </Text>
                    <Button  variant="custom" fontSize="sm" height="48px" as={Link} to="/playtestnet" 
                                width="200px" 
                                mt={10}
                                mb={20}
                            >
                                PLAY ON TESTNET
                            </Button>
                </Flex>
            </Flex>
            </AnimatedBox>
   

            </VStack>
           
   


        
    );
}