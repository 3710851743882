import { Box, Text } from '@chakra-ui/react';
import { useInView, animate, useIsomorphicLayoutEffect } from 'framer-motion';
import { useEffect, useRef } from 'react'

export const AnimatedCounter = ({from, to, animationOption, styles}) => {
    const containerRef = useRef();
    const inView = useInView(containerRef, {once:true})




    useIsomorphicLayoutEffect(()=> {
        const element = containerRef.current
        if(!element) return
        if(!inView) return

        element.textContent = String(from)

        const controls = animate(from, to, {
            duration: 1.5,
            ease: "easeOut",
            ...animationOption,
            onUpdate(value) {
                element.textContent = value.toFixed(0)
            }
        })
        return () => {
            controls.stop()
        }
    }, [containerRef, inView, from, to])

  
    return <Box ref={containerRef} style={{ position: 'relative'}} color="brand.100" {...styles} />;
  
};
