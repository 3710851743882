
/** Constant file record all the static variables used globally */
import LongGame from '../LongGame.json' // 6.23
import MultipleLongGame from '../MultipleLongGame.json'//7.15

const longGameContractV1 = "0xe24046091e415efCCa19f10D7477721ad788d130"; // April contract
const longGameContractV2 = "0x58D081f0f00a01f7490bDDB7adD5fb6c00484D6d"// deployed 5.4.2024
const longGameContractV3 = "0xF0D43Ffa731Dc5E346Acb379A522d92143449d9B"// deployed 5.6.2024
const longGameContractV4 = "0x8Ea889069a267b8C8B3d4c98A073CAb319B9aD89"// deployed 5.26.2024
const longGameContractV5 = "0x49a808621d0873E3f20a6B66c7239b2CD0cbb11e"// deployed 6.11.2024
const longGameContractMainnet = "0x2C2c95EDd6F9be3Fb1534b9705E231fb57EcFE02" // blast mainnet 6.23.2024

const mLongGameContractV3 = "0x6D3a34f5095831DbBA128C18508A2f369d144A3e" // deployed 5.7.2024
const mLongGameContractV4 = "0x19B781A108BF4D6637777B7AC38E560708249a03" // deployed 5.26.2024
const mLongGameContractV5 = "0xd4F52F56951fe789C637D31259006E33C940581f" // deployed 6.11.2024
const mLongGAmeContractReferralV1 = "0x4C535d4DB9d427De8d49dE11263022722021D6Ce" // sepolia 7.15.2024

const mLongGAmeContractMainnet = "0x286F95718C1c5De2DfE69624f06C298925b21077" // blast mainnet 6.23.2024
const mLongGAmeContractMainnetV2 = "0x0c9fe8E4d8BDED8a4c2e928cFBd6332aD980A53e" // blast mainnet(w/ referral) 7.15.2024


const blastMainnet = {
  id: 81457,
  name: 'Blast',
  network: 'Blast Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.blast.io'],
      webSocket: ['ws://rpc.blast.io']
    },
  },
  testnet: false,
  explorer: "https://blastscan.io/"
}



// Block Explorer: https://testnet.blastscan.io
//info: https://docs.blast.io/building/network-information

const blastSepolia = {
    id: 168587773,
    name: 'Blast Sepolia',
    network: 'Blast Testnet',
    nativeCurrency: {
      decimals: 18,
      name: 'Blast Sepolia Testnet',
      symbol: 'gETH',
    },
    rpcUrls: {
      default: {
        http: ['https://sepolia.blast.io'],
        webSocket: ['ws://sepolia.blast.io']
      },
    },
    testnet: true,
  }

  //https://sepolia.etherscan.io

  const Sepolia = {
    id: 11155111,
    name: 'Sepolia',
    network: 'Sepolia Testnet',
    nativeCurrency: {
      decimals: 18,
      name: 'Sepolia Testnet',
      symbol: 'ETH',
    },
    rpcUrls: {
      default: {
        http: ['https://rpc.sepolia.org'],
        webSocket: ['wss://ethereum-sepolia-rpc.publicnode.com']
      },
    },
    testnet: true,
    explorer: "https://sepolia.etherscan.io/"
  }


  const blastMainnetInfo =  {
    chain: blastMainnet,
    contractAddress: longGameContractMainnet,
    contractABI: LongGame.abi
  }

  const sepoliaTestnetInfo =  {
    chain: Sepolia,
    contractAddress: longGameContractV5,
    contractABI: LongGame.abi
  }

  export const longGameContract = longGameContractMainnet
  export const LongGameABI = LongGame

  export const mLongGameContract = mLongGAmeContractMainnetV2
  export const MultipleLongGameABI = MultipleLongGame


  export const networkInfo = blastMainnetInfo