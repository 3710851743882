import { VStack,Box } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';
//import { BrowserRouter as Router} from 'react-router-dom';
import { HashRouter as Router } from 'react-router-dom';
import { Welcome } from './Welcome';
import { Launch } from './Launch';
import { About } from './About';
import './theme/styles.css'
import Menu from './Components/Menu';
import { LeaderBoard } from './Game/LeaderBoard';

function App() {
  const isDev = process.env.NODE_ENV === 'development';
  return (
      <VStack bg="brand.0" minH="100vh">
        
         <Router>
          <Menu />
            <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/home" element={<Welcome />} />
                {isDev && <Route path="/test" element={<LeaderBoard />} /> }
                <Route path="/play" element={<Launch />} /> 
                <Route path="/about" element={<About />} /> 
                <Route path="/leaderboard" element={<LeaderBoard />} /> 
            </Routes>
          </Router>
      </VStack>
      
  )
}

export default App;
