import { extendTheme, theme as base, withDefaultColorScheme, withDefaultVariant ,
    defineStyle, defineStyleConfig
} from "@chakra-ui/react";
import { midnightTheme } from '@rainbow-me/rainbowkit';
import { buttonTheme } from "./Button";
import { TabStyle } from "./Tabs";

export const theme = extendTheme({
    colors: {
        brand : {
            0: '#000000',
            100: '#ACFF01',
            200: '#4243AF',
            300: '#FFFFFF',
            400: '#080808',
            500: '#FCFDC7',
            600: '#01FF39',
            700: '#707070',
            800: '#171717',
            900: '#E53E3E',
            1000: "#3E95E5"

        }
    },
    fonts: {
        heading: `Open Sans, ${base.fonts?.heading}`,
        body: `Open Sans, ${base.fonts?.body}`,
    },
    components: {
        Button: buttonTheme,
        Tabs: TabStyle,
      }
},
    withDefaultColorScheme({
        colorScheme: 'brand',
        components: [ 'Box']
    }),

    withDefaultVariant({
        variant: 'filled',
        components: ['Input', 'Select' ]
    }),


);

export const rainbowTheme = midnightTheme({
    accentColor: '#4243AF',
    accentColorForeground: '#ACFF01',
    borderRadius: 'none',
    fontStack: 'system',
    overlayBlur: 'small',
    generalBorder: 'yellow',
    fonts: {
        body: `Open Sans, ${base.fonts?.body}`,
    }
});


export const brand = {
    50: '#F3F8FF',
    100: '#FFA3FD',
    200: '#E384FF',
    300: '#E26EE5',
    400: '#865DFF',
    500: '#7E30E1',
    600: '#49108B',
    700: '#11235A',
    800: '#191825',
    900: '#000000',
    1200: '#CCFD07'
  }
