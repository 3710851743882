// HoverBox.js
import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { InfoCard } from './InfoCard';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

export const HoverBox = ({children, message}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
     
      {isHovered && (
        <MotionBox position="absolute" top="150%" left="0" transform="translateX(-50%)" mt={2}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.3, type: "spring",
            damping: 88,
            stiffness: 500, }}
        >
          <InfoCard message={message}/>
        </MotionBox>
      )}
      {children}
    </Box>
  );
};

