import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

const CelebrationAnimation = () => {
  const containerRef = useRef();

  useEffect(() => {
    const confettis = Array.from({ length: 50 }).map(() => {
      const div = document.createElement('div');
      div.style.width = '10px';
      div.style.height = '10px';
      div.style.backgroundColor = '#' + Math.floor(Math.random()*16777215).toString(16);
      div.style.position = 'absolute';
      containerRef.current.appendChild(div);
      return div;
    });

    confettis.forEach((confetti, i) => {
      gsap.to(confetti, {
        x: Math.random() * 1000 - 200,
        y: Math.random() * 800 - 100,
        opacity: 0,
        scale: Math.random(),
        rotation: Math.random() * 360,
        duration: 5,
        ease: 'power1.out',
        onComplete: () => confetti.remove(),
      });
    });
  }, []);

  return <div ref={containerRef} style={{ position: 'relative'}} />;
};

export default CelebrationAnimation;