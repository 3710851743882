
export const formatAddress = (address) => {
    return address.slice(0,5) + '...' + address.slice(address.length -4, address.length)
}

export const formatAvatarUrl = (seed) => {
    //return "https://api.dicebear.com/7.x/fun-emoji/svg?seed="+ seed + "&size=68&radius=50" 
    // see https://www.dicebear.com/styles/bottts/
    return "https://api.dicebear.com/8.x/bottts/svg?seed="+ seed + "&size=68&radius=50&baseColor=ACFF01&mouth=bite,diagram,grill01" 
}

export function parseQueryString(queryString) {
    const params = new URLSearchParams(queryString);
    return Array.from(params.keys()).reduce((acc, key) => ({
      ...acc,
      [key]: params.get(key),
    }), {});
}