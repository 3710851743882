import { VStack, Box } from "@chakra-ui/react";

import {Header} from "./Components/Header";
import { About } from "./Components/AboutLongGame";
import { OurTeam } from "./Components/OurTeam";
import { Offer } from "./Components/Offer";
import { ActionFooter } from "./Components/ActionFooter";
import Menu from "./Components/Menu";

export function Welcome() {
    return(
        <VStack width="100%" overflow='hidden'  >
            <Header />

        </VStack>
    );
}