import { Box,  Flex,  Divider, Center } from "@chakra-ui/react";
import Bb1 from '../images/header-bg1.png'
import { DragonClock } from "./DargonClock"
import { JoinGame } from "../Game/JoinGame"
import { Activities } from "../Game/Activities"
import { ShareTwitter } from "./ShareTwitter"
import { PlayerList } from "./PlayerList"
import { GameStats } from "./GameStats"
import { useState, useEffect, useRef} from 'react'
import { ethers } from 'ethers'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { networkInfo } from "../utils/config";
import { getContract, getMultiplContract } from "../utils/contractUtil";
import { AnimatedAlert } from "../AnimateComponents/AnimatedAlert"
import { AlertStatus } from '../utils/constant'
import { entryFee, gameDuration } from "../utils/constant";
import { TutorialStyleOptions, joinGameContent, ActivityTutorialContent, ShareTwitterContent } from "./Tutorial";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { AnimatedBanner} from "../AnimateComponents/AnimatedBanner";
import { BlastBanner } from "../Components/BlastBanner";

export function Game() {

    // UI States
    const [joining, setJoining] = useState(false);
    const [joinTxInfo, setJoinTxInfo] = useState("")
    const[ alertData, setAlertData] = useState({})
    const [tutorialState, setTutorialState] = useState({})

    // Game States
    const [joinCnt, setJoinCnt] = useState(0) 
    const [currentRound, setCurrentRound] = useState(-1)
    const [lastestRound, setLatestRound] = useState(-1)
    const [isGameEnded, setIsGameEnded] = useState(true)
    const [endTime, setEndTime] = useState(new Date())


    // wallet state
    const { chain } = useNetwork();
    const [isCorrectNetwork, setIsCorrectNetwork] = useState(chain?.id === networkInfo.chain.id);
    const { address } = useAccount();

    // Tutorial 
    const joinGameRef = useRef()
    const activityRef = useRef()
    const shareTwitterRef = useRef()

    // Fetch contract info
    useEffect(() => { 
        const networkOk = chain?.id === networkInfo.chain.id
        setIsCorrectNetwork(networkOk)
        const {provider, contract} = getContract('provider')
        if(contract && networkOk) {
            getGameStats(contract)
        }
    }, [joinCnt, chain])

    useEffect(() => { 
        setTutorialState({
            run: true,
            steps: [
                {
                  content: joinGameContent(),
                  placementBeacon: "top",
                  placement: 'bottom',
                  spotlightClicks: true,
                  disableScrolling: true,
                  styles: {
                    options: TutorialStyleOptions
                  },
                  target: joinGameRef.current,
                },
                {
                    content: ActivityTutorialContent(),
                    placement: 'bottom',
                    spotlightClicks: true,
                    disableScrolling: true,
                    styles: {
                      options: TutorialStyleOptions
                    },
                    target: activityRef.current,
                  },
                  {
                    content: ShareTwitterContent(),
                    placement: 'bottom',
                    disableScrolling: true,
                    spotlightPadding: 0,
                    styles: {
                      options: TutorialStyleOptions
                    },
                    target: shareTwitterRef.current,
                  },
              ]
        })  
    }, [])

    async function getGameStats(contract) {
        try{
            // check if there is active game
            const game_count_uint = await contract.gameCount()
            const game_count = Math.floor(game_count_uint/1)
            if(game_count > 0) {
                
                // game data index start at 0, gamecount is the length of the queue
                const lastest_game_round = await contract.games(game_count -1)

                const endTime = new Date(Math.floor(lastest_game_round.lastParticipantTime/1)*1000 + gameDuration);
                const lastRoundEnded = endTime.getTime() <= new Date().getTime()
                setEndTime(endTime)

                setCurrentRound(lastRoundEnded? -1 : game_count)
                setLatestRound( game_count)
                setIsGameEnded( lastRoundEnded)
            }
        }catch(error) {
            console.error(error)
            setAlertData({
                alertOpen: true,
                message: "Please check your network or wallet connection, then refresh...",
                alertType: AlertStatus.WARNING,
            })
        }
    }

    async function joinGame(count, referralCode) {
        setJoining(true)
        setJoinTxInfo("Joining")
        const referredBy = referralCode.length > 0 ? referralCode : ''

        if (typeof window.ethereum !== 'undefined') {
        try {
            const contract = getMultiplContract()
            const tx = await contract.joinETH(count, referredBy, {value: ethers.utils.parseEther((entryFee * count).toString())} )
            setAlertData({
                alertOpen: true,
                message: "Transaction sent",
                url: networkInfo.chain.explorer + "tx/" + tx.hash.toString(),
                alertType: AlertStatus.SUCCESS,
            })
            setJoinTxInfo("Waiting confirmation " + tx.hash.slice(0, 8) + " ...")
            await tx.wait(); // Wait for the transaction to be mined
            setJoinCnt(joinCnt +1)
            setJoining(false);
            
          } catch (error) {
            setJoining(false);
            setAlertData({
                alertOpen: true,
                message: "Something went wrong: " + error.reason,
                alertType: AlertStatus.WARNING,
            })
            setJoinTxInfo("Error")
          }
        } else {
            console.log('MetaMask is not installed!');
            setJoining(false);
            setJoinTxInfo("")
            setAlertData({
                alertOpen: true,
                message: "Can't find wallet extension.",
                alertType: AlertStatus.ERROR,
            })
        }
    }

    const onAlertExit = () => {
        setAlertData("")
    }
    
    const handleJoyrideCallback = (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        if (finishedStatuses.includes(status)) {
          setTutorialState({ run: false });
        }
      };

    const { run, steps } = tutorialState

    return(
            <Flex bgImage={`url(${Bb1})` }  
                width="100%" 
                minHeight="100vh"
                bgPosition="Bottom"
                backgroundRepeat="no-repeat"
                bgSize="contain"
                direction="column"
            >
                <AnimatedBanner />
                <Joyride
                callback={handleJoyrideCallback}
                continuous
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                styles={{
                options: {
                    zIndex: 10000,
                },
                }}
            />

                { alertData?.alertOpen && <AnimatedAlert 
                    {...alertData}
                    onAlertExit={onAlertExit} 
                />}
                <Flex  direction={{base: "column", md:"row"}} mt={{base: "0", md: 2}} mx="5%" justifyContent="center">
                    <Flex direction="column"  justifyContent={{base:"center", md: "flex-start"}} alignItems="center">
                        <DragonClock isGameEnded={isGameEnded} targetDate={endTime}/>
                        <GameStats roundId={currentRound} endTime={endTime} isGameEnded={isGameEnded} />
                    </Flex>
                    <Flex direction="column" justifyContent="space-between" align="center">
                        <Flex 
                            width={{base:"95%", md:"40vw", xl: "500px"}} 
                            backgroundColor="brand.800" 
                            direction="column"
                        >
                            <Box ref={joinGameRef}>
                                <JoinGame 
                                    address={address} 
                                    joinGame={joinGame} 
                                    currentRound={currentRound}
                                    joining={joining} 
                                    joinTxInfo={joinTxInfo}
                                    isGameEnded={isGameEnded}
                                    joinCnt={joinCnt}
                                />
                            </Box>
                            <Center height="2px" mt={8}>
                                <Divider borderColor="brand.700" width="90%"/>
                            </Center>
                            <Box ref={activityRef}>
                                <Activities 
                                    isGameEnded={isGameEnded} 
                                    currentRound={currentRound} 
                                    joinCnt={joinCnt}
                                />
                            </Box>

                            <Center height="2px" mt={8}>
                                <Divider borderColor="brand.700" width="90%"/>
                            </Center>
                          
                           <Box ref={shareTwitterRef}>
                           <ShareTwitter  />  
                           </Box>
                            
                   
  
                            
                        </Flex>
                        <PlayerList 
                            isGameEnded={isGameEnded} 
                            currentRound={currentRound}
                        />
                    </Flex>

                </Flex>
                <BlastBanner />

                </Flex>
            
      
    )
}

