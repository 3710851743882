import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import '@rainbow-me/rainbowkit/styles.css';
import { connectorsForWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { injectedWallet, metaMaskWallet, walletConnectWallet, coinbaseWallet } from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createClient, WagmiConfig } from 'wagmi';

import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { ChakraProvider } from '@chakra-ui/react'
import { ApolloProvider } from '@apollo/client';
import { ColorModeScript } from "@chakra-ui/react";
import { NativeCurrencyProvider } from './providers/NativeCurrencyProvider'; 
import {theme, rainbowTheme } from "./theme/index";

import {networkInfo } from "./utils/config"
import { graphQLClient, backendClinet } from './graphql/apollo-client';





const { provider, chains } = configureChains(
  [networkInfo.chain],
  [
    jsonRpcProvider({
      rpc: chain => ({ http: chain.rpcUrls.default.http[0] }),
    }),
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      injectedWallet({ chains }),
      metaMaskWallet({ chains }),
      walletConnectWallet({ chains }),
      coinbaseWallet({ chains, appName: 'The Long Game' }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={graphQLClient}>
    <ApolloProvider client={backendClinet}>
      <WagmiConfig client={wagmiClient}>
        <NativeCurrencyProvider>
        <RainbowKitProvider chains={chains} theme={rainbowTheme} coolMode >
          <ChakraProvider theme={theme}>
              <ColorModeScript initialColorMode={theme.config.initialColorMode} />
              
                <App />
              
          </ChakraProvider>
        </RainbowKitProvider>
        </NativeCurrencyProvider>
      </WagmiConfig>
    </ApolloProvider>  
   </ApolloProvider>

  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
