import { Box, HStack, Flex, Text, Image, Spinner, Center } from "@chakra-ui/react";
import { RecentPayout } from "../graphql/RecentPayoutsV2";
import { RecentJoiners } from "../graphql/RecentJoiner";
import MoreIcon from "../images/more-horiz.svg"
import { textShadowValue } from "../utils/constant"
import { useState, useEffect } from 'react'


export function PlayerList({isGameEnded, currentRound}) {
    const [showSpinner, setShowSpinner] = useState(false)


    useEffect(() => {
        // Function to handle the spinner display
        const handleSpinnerDisplay = () => {
          // Show the spinner
          setShowSpinner(true);
    
          // Hide the spinner after 5 seconds
          setTimeout(() => {
            setShowSpinner(false);
          }, 5000);
        };
    
        // Initially call the handleSpinnerDisplay after 10 seconds
        const timer = setTimeout(handleSpinnerDisplay, 10000);
    
        // Set up an interval to repeat the behavior every 15 seconds
        const interval = setInterval(handleSpinnerDisplay, 15000);
    
        // Clean up the interval and timeout when the component unmounts
        return () => {
          clearInterval(interval);
          clearTimeout(timer);
        };
      }, []);

    return(
        <Flex
        width={{base:"95%", md:"40vw", xl: "500px"}} 
        
        direction="column"
        >
            <Flex 
            backgroundColor="brand.800" 
            direction="column"
            
            mt='8'
            minHeight="200px"
        >
                <Flex direction="column" justifyContent="center" alignContent="center"
                    width={{base:"95%", md:"40vw", xl: "500px"}} 
                    mx={6}
                    mt={8}
                    pb={6}
                >
                    <Text fontSize={{base: "md", md: "lg"}} color="brand.100" 
                        fontWeight="semibold" alignItems="left"
                        textShadow={textShadowValue}
                    >
                        Lastest Players
                    </Text>
                    {showSpinner && 
                            <Center mt={3}> 
                                <Spinner color="brand.100" size={{base: "sm", md: "md"}}  speed="1s" emptyColor='brand.200'/>
                            </Center>
                    }
                        <RecentJoiners />
                    <Center>
                        <Image src={MoreIcon} boxSize={{base: "18px", md: "24px"}} align="center" mt="6" />
                    </Center>
                </Flex>
            </Flex>
            <Flex 
                
                backgroundColor="brand.800" 
                direction="column"
                minHeight="200px"
                mt='8'
                
            >
            <Flex direction="column" justifyContent="center" alignContent="flex-start"
                width={{base:"95%", md:"40vw", xl: "500px"}}
                mx={6}
                mt={8}
                pb={6}
            >
                <Text fontSize={{base: "md", md: "lg"}} color="brand.100" 
                    fontWeight="semibold" alignItems="left"
                    textShadow={textShadowValue}
                >
                    Latest Winners
                </Text>
                {showSpinner && 
                    <Center mt={3}> 
                        <Spinner color="brand.100" size={{base: "sm", md: "md"}} speed="1s" emptyColor='brand.200'/>
                    </Center>
                }
               <RecentPayout isGameEnded={isGameEnded} currentRound={currentRound}/>
                <Center>
                    <Image src={MoreIcon} boxSize={{base: "18px", md: "24px"}} align="center" mt="6" />
                </Center>
            </Flex>
            </Flex>
        </Flex>
        
                    


          
            
           
    )
}

