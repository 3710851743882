import { Center, Flex, Text, Button, Image, HStack, Skeleton, Box, useMediaQuery, Tooltip } from "@chakra-ui/react";
import HistoryIcon from '../images/history.png'
import { gql, useLazyQuery } from '@apollo/client';
import { graphQLClient } from '../graphql/apollo-client';
import { useState, useEffect } from 'react'
import { useAccount , useNetwork} from 'wagmi';
import { HistoryModal } from "./HistoryModal";
import {formatAvatarUrl} from "../utils/utils"
import { entryFee, textShadowValue } from "../utils/constant"
import { getContract } from "../utils/contractUtil";
import { ClaimModal } from "./ClaimModalV2";
import  { AnimatedProgress } from "../AnimateComponents/AnimatedProgress"
import { useNativeCurrency } from '../providers/NativeCurrencyProvider'; 
import { GameTooltip } from "./GameTooltip"
import { AnimatePresence } from "framer-motion";
import { InfoIcon } from '@chakra-ui/icons'
import { ethers } from 'ethers';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

import { UserDetails } from "./UserDetails";
import { AnimationBouncing } from "../AnimateComponents/AnimationBouncing"
/**
    {
        "createdAt": 1715050344,
        "id": "0x4F96AB31bbbdb65FBac48Bca54304BF64caDdaCD+1",
        "lastJoinedAt": 1715050344,
        "positions": [ 1,2 ],
        "roundId" : 1,
        "totalPositionCnt": 2
    }    
 */

const GET_HISTORY_DATA = gql`
query GetUserHistoryQuery($id: String, $roundId: Int!) {
    users(where: {id_contains: $id} ) {
        items {
            id
            lastJoinedAt
            positions
            roundId
            totalPositionCnt
            createdAt
        }
    }
    payouts(where: {id_contains: $id} ) {
        items {
            id
            lastExitedAt
            roundId
            exitedPositionCnt
        }
    }
    claims(where: {id_contains: $id} ) {
        items {
            id
            roundId
            claimAmount
        }
    }
    game(id: $roundId) {
        totalPositions, 
        nextPositionToExit
        }
}
`;

export const RoundStatus = Object.freeze({
    EXITED: 'ALL EXITED 200%',
    LONG_ELIGIBLE: '$LONG ELIGIBLE',
    CLIAMED: 'CLAIMED'
});


export function Activities({isGameEnded, currentRound, joinCnt }) {

    const { address } = useAccount();
    const [ joined, setJoined] = useState(false)
    const [ roundInfos, setRoundInfos] = useState([])
    const nativeCurrencySymbol = useNativeCurrency();
    const [ currentGameInfo, setCurrentGameInfo] = useState({})
    // Modal state 
    const [ openHistoryModal, setOpenHistoryModal] = useState(false)
    const [ openClaimModal, setOpenClaimModal] = useState(false)
    const [ openUserDetailsModal, setOpenUserDetailsModal] = useState(false)
    const [ claimInfo, setClaimInfo] = useState({})
    const [ claiming, setClaiming] = useState(false)
    const [ claimTxInfo, setClaimTxInfo] = useState("")
    // total rounds, total spent, total earned, total claimed
    const [totalStats, setTotalStats] = useState({})
    const [ txError, setTxError] = useState("")
    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
    
    const [getHisotry, { loading, data, error }] = useLazyQuery(GET_HISTORY_DATA, {
        pollInterval: 10000 , // Poll every 10 seconds
        client: graphQLClient
    });

    useEffect(()=> {
        if(address) {
            getHisotry({ variables: { id: address, roundId: currentRound } });
        }
    }, [address, getHisotry, currentRound])

    useEffect(() => {
        if (data && data.users && data.payouts && data.users.items && data.users.items.length > 0 ) {
            setJoined(true)
            if(data.game) {
                setCurrentGameInfo(data.game) 
            }

            const historyInfos = [...data.users.items, ...data.payouts.items, ...data.claims.items].reduce((acc, item) => {
                if(acc[item.id]) {
                    acc[item.id] = { ...acc[item.id], ...item };
                } else {
                    acc[item.id] = item;
                }
                return acc;
            }, {});

            // construct the history data
            const histories = Object.values(historyInfos).map( history => {
                const exitedPositionCnt = history.exitedPositionCnt || 0
                const roundInfo = {
                    roundId: history.roundId,
                    nextPosition: history.positions.slice(exitedPositionCnt)[0], // remove exited position, return the next position
                    totalPositionCnt: history.totalPositionCnt,
                    exitedPositionCnt: exitedPositionCnt,
                    positions: history.positions,
                    claimAmount: history.claimAmount || 0, // only show after user claimed $LONG
                    roundStatus: history.claimAmount > 0 ? RoundStatus.CLIAMED
                        : (!history.exitedPositionCnt || history.totalPositionCnt > history.exitedPositionCnt) ? RoundStatus.LONG_ELIGIBLE 
                            : RoundStatus.EXITED
                }
                
                return roundInfo
            })
             histories.sort((a,b) => b.roundId - a.roundId)
            // filter out current round info
            
            // calculate total status
            calculateTotalGameStats(histories)
            
            setRoundInfos(histories)
        }
      }, [data]);

    const calculateTotalGameStats = (histories ) => {
        const totalRounds = histories.length
        const allPositionCnt = histories.reduce( (accumulator, cur) => {
            return accumulator + cur.totalPositionCnt
        }, 0)
        const allExitedCnt = histories.reduce( (accumulator, cur) => {
            return accumulator + cur.exitedPositionCnt
        }, 0)
        
       const totalClaimed = histories.reduce( (accumulator, cur) => {
            const a = ethers.BigNumber.from(cur.claimAmount )
            return accumulator.add(a );
        }, ethers.BigNumber.from(0))
        

        setTotalStats({
            totalRounds: totalRounds,
            totalSpent: allPositionCnt * entryFee,
            totalEarned: allExitedCnt * 2 * entryFee,
            totalClaimed: parseFloat(ethers.utils.formatEther(totalClaimed)).toLocaleString()
        })
    }


    const handleHistoryModalClose = () => {
        setOpenHistoryModal(false)
    }
    const handleClaimModalClose = () => {
        setOpenClaimModal(false)
        setClaimTxInfo("")
    }
    const handleUserDetailsModalClose = () => {
        setOpenUserDetailsModal(false)
    }

    function confirmClaim(amount, round) {
        setOpenClaimModal(true)
        setClaimInfo({amount: amount, round: round})
    }

    const showCurrentRoundInfo = () => {
            const currentRoundInfo = roundInfos.filter(r => r.roundId === currentRound)
            const nextPositionToExit = currentGameInfo.nextPositionToExit
            const userNextPosition = currentRoundInfo[0].nextPosition
            const diff = userNextPosition - nextPositionToExit
            const allExited = currentRoundInfo[0].totalPositionCnt === currentRoundInfo[0].exitedPositionCnt

            return (

                <Flex direction="row" justify="space-between" mt={6} width="inherit">
                <HStack>
                    <Image src={formatAvatarUrl(address)} boxSize="24px" />
                    <Text fontSize={{base: "sm", md: "md"}}  color="brand.100" mr={6}>Round {currentRound} </Text>
                </HStack>
                <HStack>
                    <Flex direction="column" justify="space-between" height={9} alignItems="flex-end" cursor="pointer" 
                        onClick={()=> setOpenUserDetailsModal(true)} 
                    >
                        <Text fontSize={{base: "sm", md: "sm"}}  color="brand.700" fontWeight="semibold" 
                            _hover={{ color: "brand.100", textDecoration: 'underline'}}>
                            { allExited ?  "All exited with 200% return ":
                                diff === 0? "You are the next to EXIT!" :  `${diff} players before you`
                            } 
                        </Text>
                        <AnimatedProgress 
                            value={allExited? 100 : diff === 0 ? 100 : 100 - 100 * (diff) / ((diff) * 3)}  
                        />
                    </Flex>
                    
                    <AnimationBouncing>
                        <MdKeyboardDoubleArrowRight 
                            color="#ACFF01" 
                            size="24px"
                            cursor="pointer" 
                            onClick={()=> setOpenUserDetailsModal(true)} 
                        /> 
                    </AnimationBouncing>
                </HStack>
                </Flex>
            )
        }


          

    const showUserDetails = () => {
        const currentRoundInfo = roundInfos.filter(r => r.roundId === currentRound)[0]
        return (
            
                <UserDetails handlClose={handleUserDetailsModalClose} 
                    currentRoundInfo={currentRoundInfo}
                    currentGameInfo={currentGameInfo}
                />
            
        )
    }
    

    async function claimToken(round){
        setClaiming(true)
        setClaimTxInfo("Claiming")
        if (typeof window.ethereum !== 'undefined') {
            try {
                const { _ , contract} = getContract('signer')
                const tx = await contract.claimToken(round )
                setClaimTxInfo("Waiting confirmation " + tx.hash.slice(0, 8) + " ...")
                await tx.wait(); // Wait for the transaction to be mined
                setClaimTxInfo("Succeed")
                setClaiming(false)
                // fetch if there is payout event when someone join here
                
              } catch (error) {
                setClaiming(false);
                console.log('Claim Token return error:', error)
                setTxError( "Something went wrong: " + error.reason)
                
              }
            } else {
                console.log('MetaMask is not installed!');
                setClaiming(false);
                setTxError("Please connect your wallet to claim!")
            }
    }

    return(
        <Flex  direction="column" justifyContent="center" alignContent="center"
            mx={6}
            mt={6}
        >   
        <AnimatePresence
            initial={false}
            mode="wait"
        >
            {openHistoryModal && 
           
                <HistoryModal 
                    handlClose={handleHistoryModalClose}
                    nativeCurrencySymbol={nativeCurrencySymbol}
                    roundInfos={roundInfos.filter(r=> r.roundId !== currentRound)}
                    totalGameStats={totalStats}
                />  
         
            }
            {openClaimModal && <ClaimModal 
                handlClose={handleClaimModalClose}
                claimInfo={claimInfo}
                claimTxInfo={claimTxInfo}
                claiming={claiming}
                onClaimToken={claimToken}
                />
            }

            {openUserDetailsModal && showUserDetails()}

        </AnimatePresence>            
            <Flex direction="row" justify="space-between" align="center">
                <Text fontSize={{base: "md", md: "lg"}} color="brand.100" fontWeight="semibold" alignItems="left" 
                    textShadow={textShadowValue}
                >
                    Your Activities
                    <GameTooltip 
                    message='Your current round acitivity and available $LONG to claim display here'>  
                    <InfoIcon w={4} h={4} ml={2} color="brand.700"/>
                    </GameTooltip>
                </Text>
                <HStack>
                    <Image  src={HistoryIcon} boxSize="24px" />
                        <Button  onClick={()=> setOpenHistoryModal(true)} disabled={! (address && joined) }
                            variant="link" color="#3E95E5" fontSize={{base: "sm", md: "md"}}  fontWeight="semibold"
                        >
                            History
                        </Button>
                </HStack>
                 
            </Flex>
            <Skeleton isLoaded={!loading} minHeight="100px"
                    startColor='brand.400' endColor='brand.400'
            >
                {error && <Text color="brand.700">Error...</Text>}
                { address && joined ? 
                    <Flex direction="column" justify="center" align="space-between" > 
                    {/* Show current round info, and past round that has $LONG eligible */}
                        { !isGameEnded && currentRound >0 && roundInfos.filter(r => r.roundId === currentRound).length > 0 &&
                            <Box>
                                {showCurrentRoundInfo()}
                            </Box>
                        }
                
                        { roundInfos.filter(h => h.totalPositionCnt > h.exitedPositionCnt 
                                    && !h.claimAmount && h.roundId !== currentRound)
                                    .map((history , index)=> (
                            <Flex direction="row" justify="space-between" mt={6} key={index}>
                                <HStack>
                                    <Image src={formatAvatarUrl(address)} boxSize="24px" />
                                    <Text fontSize={{base: "sm", md: "md"}}  color="brand.100" mr={6}>Round {history.roundId}</Text>
                                </HStack>
                                <Box>
                            
                                <Button  
                                        onClick={()=>confirmClaim("", history.roundId)}
                                        variant="link" color="brand.200"
                                        fontSize={{base: "sm", md: "md"}} 
                                        fontWeight="semibold"
                                        width="100%"
                                        margin="auto"
                                    >
                                        Claim $LONG
                                </Button>
                                </Box>
                            </Flex>
                        ))}
                    </Flex>
                :
                <Box>
                    { address? 
                        <Center>
                        <Text fontSize={{base: "sm", md: "md"}} color="brand.700" fontWeight="semibold" mt={6}>
                            No Current Game Activities 
                        </Text>
                        </Center>:
                        <Center>
                            <Text fontSize={{base: "sm", md: "md"}} color="brand.700" fontWeight="semibold" mt={6}>
                                Please Connect Your Wallet
                            </Text>
                        </Center>  
                    }
                </Box>
             
                }


            </Skeleton>
            
        </Flex>
    );
}