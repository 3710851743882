import React, { useRef, useState } from 'react';
import { Box, Text, Icon, useBoolean, Button } from '@chakra-ui/react';
import { BiSolidVolumeMute } from "react-icons/bi";
import { AiFillSound, AiFillMuted } from "react-icons/ai";


export const BackgroundAudio = ({ src, showText}) => {
    const audioRef = useRef(null);
    const [audioStarted, setAudioStarted] = useState(false);
    const [isMuted, setIsMuted] = useBoolean(true);

    // Function to start the audio
    const startAudio = () => {
        if (audioRef.current ) {
            audioRef.current.play()
            .then(() => {
                setAudioStarted(true);
                setIsMuted.toggle();
            })
            .catch((error) => {
                console.log('Playback failed:', error);
            });
        }
    };

    // Toggle mute state
    const toggleMute = () => {
        if (audioRef.current) {
            audioRef.current.muted = !audioRef.current.muted;
            setIsMuted.toggle();
        }
    };

    return (
        <>
            <audio ref={audioRef} loop src={src} display="none" />
          

                <Button
                    onClick={audioStarted? toggleMute: startAudio}
                    aria-label={isMuted ? 'Unmute' : 'Mute'}
                    colorScheme="#080808"
                    variant='link'
                    leftIcon={isMuted ? 
                        <Icon as={BiSolidVolumeMute} boxSize="24px" color="#707070" /> : 
                        <Icon as={AiFillSound} boxSize="24px" color="#707070" />
                        }
                >
                    {showText &&  
                        <Text display="in-line" as="span"  color="brand.700" fontSize="md" fontWeight="semibold" > Audio</Text>  
                }
                </Button>

            
        </>
    );
};

