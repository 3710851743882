import { StepCard } from "./StepCard";
import { Box, HStack, Flex, Text, SimpleGrid, Image, Center, Button } from "@chakra-ui/react";
import OfferFg from '../images/offer.png'
import OfferBg from '../images/offer-bg.png'
import { textShadowValue } from "../utils/constant";

import GemsIcon from '../images/gems.png'
import { AnimationBouncing } from "../AnimateComponents/AnimationBouncing";
import { DoubleImage } from "../AnimateComponents/DoubleImage";
import { AnimatedBox } from "../AnimateComponents/AnimatedBox";



export function Offer() {
    return(
            <Flex 
                width="100%" 
                minH="100vh"
                bgPosition="center"
                backgroundRepeat="no-repeat"
                bgSize="cover"
                direction="column" 
                justifyContent="center"
                alignItems="center"
            >
                <Flex direction={{base:"column", md:"row" }} 
                    justifyContent="center" alignItems={{base:"center", md:"flex-start" }} 
                    >
                    <Flex direction="column" justifyContent="center" alignItems="center" mr={{base: 0, md: 15}}>
                        <AnimatedBox distance={-300}>
                            <Text fontWeight="semibold" fontSize={{base:"2xl", md: "4xl"}}
                                textShadow={textShadowValue}
                                color="brand.100"  mt="10%" mb={20}> 
                                How Long Game works?
                            </Text>
                        </AnimatedBox >
                        <DoubleImage fgImg={OfferFg} bgImg={OfferBg} alt='offer' distance={-300}/>
                    </Flex>
                   
                    <Flex direction="column" justifyContent="center" alignItems="center"  ml={{base:"0", md: 15}} w={{base: "80%", md: "500px"}}>
                        <AnimatedBox distance={300}>
                        <Flex direction="column"  mt={14} key={1} >
                            <HStack>
                            <AnimationBouncing>
                                <Image display="inline" src={GemsIcon} alt='gems' objectFit='cover' />
                            </AnimationBouncing>
                                <Text fontWeight="semibold" fontSize={{base: "md", md: "xl"}} color="brand.100">
                                    Early Birds, Unmatched Odds

                                </Text>
                            </HStack>
                            <Text fontSize="sm" color="brand.700"  mt="24px" ml="35px">
                                Join early, win big — (pretty much) guaranteed. In Long Game, every player scores, especially the early birds. Jump in early and your odds of snagging a 200% return are unmatched. The game keeps rolling and so do your chances to win big. 
                            </Text>
                        </Flex>
                        <Flex direction="column"  mt={14} key={2} >
                            <HStack>
                            <AnimationBouncing>
                                <Image display="inline" src={GemsIcon} alt='gems' boxSize="28px" objectFit='cover' />
                            </AnimationBouncing>
                                <Text fontWeight="semibold" fontSize={{base: "md", md: "xl"}} color="brand.100">
                                Zero Rake, Maximum Take

                                </Text>
                            </HStack>
                            <Text fontSize="sm" color="brand.700"  mt="24px" ml="35px">
                            We're not playing games with your coin. At Long Game, what you bet is what’s in play. We don’t skim off the top; every single Ether stays in the game. Transactions are transparent and conducted on-chain, ensuring everything is fair and square. Here, we’ve eliminated the rake to level the playing field and boost your odds, making every game a fair fight.
                            </Text>
                        </Flex>
                        <Flex direction="column"  mt={14} key={3} >
                            <HStack>
                                <AnimationBouncing>
                                <Image display="inline" src={GemsIcon} alt='gems' boxSize="28px" objectFit='cover' />
                                </AnimationBouncing>
                                
                                <Text fontWeight="semibold" fontSize={{base: "md", md: "xl"}} color="brand.100">
                                Loyalty and Patience Pays Off
                                </Text>
                            </HStack>
                            <Text fontSize="sm" color="brand.700"  mt="24px" ml="35px">
                            Stick with us, win more with us. Long Game isn’t just a game; it’s a community. We reward those who recognize our values and choose to stay and play. The longer you’re part of Long Game, the more you’ll uncover — from escalating rewards to exclusive opportunities. Stay longer, play smarter, and watch as your crypto grows in ways you never imagined.
                            </Text>
                        </Flex>
                    </AnimatedBox>

                      
                        
                        
                    </Flex>

                </Flex>

                
            </Flex>
   


        
    );
}