
import {motion} from "framer-motion"
import { Center } from "@chakra-ui/react";

export function Backdrop({children, onClick}) {
    return (
        <motion.div onClick={onClick}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                background: "#000000e1",
                zIndex: "1000",
                alignItems: "center",
                justifyContent:"center",
                disply: "flex"
            }}
            initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            exit={{ opacity: 0}}
        >
         
            {children}
            
            
            
        </motion.div>
    )
}