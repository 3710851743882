import { Box, HStack, Flex, Text, SimpleGrid, Image, Center, Button } from "@chakra-ui/react";
import DragonCircle from '../images/dragon-circle.svg'
import DragonGame from '../images/dragon-game.png'
import DragonGameMask from '../images/dragon-game-mask.png'
import { gameDuration } from "../utils/constant";
import { useState, useEffect } from 'react'
import {
    motion,
  } from "framer-motion";



export function DragonClock({isGameEnded, targetDate}) {
    const maskHeight = 260;
    const [startPosition, setStartPosition] = useState(0) 
    const [duration, setDuration] = useState(0)
    return(
            <Center position="relative"  
                    height={{base: "350px", md: "480px", xl: "600px", }} 
                    width={{base: "350px", md: "480px", xl: "600px", }} 
                >
                    {/* Dragon circle  */}
                    
                        <motion.div 
                            style={{
                                width:"100%",
                                height: "100%",
                                position:"absolute",
                                zIndex:"20",
                                
                            }}
                        animate={{rotate: 360, opacity:1}}
                        transition={{
                            duration: 12, // Duration of one full rotation
                            repeat: Infinity, // Repeats the animation forever
                            ease: "linear" // Ensures the speed is constant
                        }}
                        >
                            <Image src={DragonCircle}  
                                position="relative"  
                                left={{base: "6"}} 
                                objectFit="cover"
                            />
                            
                        </motion.div>
                        <Box boxShadow="0px 0px 35px rgba(172, 255, 1, 0.8)" 
                            position="absolute" 
                            width={{base: "250px", md: "350px", xl: "435px"}} 
                            height={{base: "250px", md: "350px", xl: "435px"}} 
                            
                            borderRadius="50%"></Box>

                    {/* Dragon self  */}
                    {!isGameEnded ? 
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration:2}}
                    >
                        
                        <Image src={DragonGame} 
                            position="relative"
                            boxSize={{base: "200px", md: "260px", xl: "350px"}}  objectFit="contain"
                        />
                        

                     </motion.div>
                     :                   
                    <Box>
                        <Image src={DragonGameMask} 
                            position="relative"
                            boxSize={{base: "200px", md: "260px", xl: "350px"}}  objectFit="contain"
                        />
                    </Box>
                    }
            </Center>
           
    )
}

