import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system"

const baseStyle = defineStyle({
  borderRadius: 0, // disable the border radius
  fontWeight: "normal", // change the font weight to normal
  font: "Open Sans", // change the font family to monospaced
})

const sizes = {
  md: defineStyle({
    fontSize: "sm", // Change font size to sm (14px)
  }),
}

// Defining a custom variant
const customVariant = defineStyle((props) => {
  const { colorScheme: c } = props
  return {
    bg: `${c}.200`,
    color: `${c}.100`,
    fontWeight: "bold",
    boxShadow: "-2px 2px 6px 0px #ACFF01",
    
    transition: 'transform 0.15s ease-out, background 0.15s ease-out',
    _dark: {
      bg: `${c}.50`,
      color: `${c}.100`,
    },

    _hover: {
      transform: "scale(1.01, 1.01)",
      bg: `${c}.100`,
      color: `${c}.200`,
      borderColor: `${c}.200`,
      border: "1px",

      _dark: {
        bg: `${c}.300`,
      },
    },

    _active: {
      bg: `${c}.200`,
      transform: "scale(1, 1)",

      _dark: {
        bg: `${c}.400`,
      },

  
    },
    _disabled: {
      bg: `${c}.0`,
      transform: "scale(1, 1)",
      color: `${c}.700`,
      borderColor: `${c}.200`,
      border: "1px",

      _dark: {
        bg: `${c}.400`,
      }
    },
    _loading: {
      bg: `${c}.400`,
      transform: "scale(1, 1)",
      color: `${c}.700`,
      boxShadow: "-4px 4px 6px 0px rgba(70, 70, 70, 0.6)",
      _dark: {
        bg: `${c}.400`,
      },
      _before: {
        content: '""', // Use a pseudo element to overlay the loader
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }
    },
  }
})

export const buttonTheme = defineStyleConfig({
  baseStyle,
  sizes,
  variants: {
    custom: customVariant,
  },
  defaultProps: {
    colorScheme: "brand", // set the default color scheme to purple
  },
})