import Logo from '../images/long-game-logo.png'
import Social from './Social';
import {Flex, Box, Image, useMediaQuery, Button, Center} from '@chakra-ui/react';
import { Topbuttons } from './topbuttons';
import MenuHamgurgerIcon from '../images/menu_hamburger.svg'
import MenuCloseIcon from '../images/menu_close_icon.svg'
import {
    useDisclosure,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerCloseButton,
    VStack,
    HStack,

  } from '@chakra-ui/react'
import { NavLink, Link } from 'react-router-dom';

import { HamburgerIcon } from '@chakra-ui/icons';
import LogoSquare from '../images/long-game-logo-square.svg'
import BbAudio from "../media/bg-audio.mp3"
import { BackgroundAudio } from "./BackgroundAudio"
import { ConnectButton } from '@rainbow-me/rainbowkit';


export default function Menu() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
    const activeLinkColor='#FFFFFF'
    const notActiveLinkColor='#ACFF01'
    return (
        <Box width="100%" height="100%"> 
        {isLargerThan768? 
            <Flex bg='brand.400' textColor='brand.100'
            align='center' gap='2' width="100%" height="60px" justify="space-between">
                <Flex direction="row">
                   <Image disply="in-line" src={Logo} mx={6} width="100px" height="24px" objectFit='cover'></Image>
                   <HStack justify='space-around' spacing={8} align="center" fontWeight='600' fontSize='2lg' color="brand.50">
                   <NavLink
                        to="/home"
                        style={({ isActive }) => ({
                        color: isActive ? activeLinkColor : notActiveLinkColor,
                        })}
                    >
                        Home
                    </NavLink>
                    <NavLink
                        to="/play"
                        style={({ isActive }) => ({
                        color: isActive ? activeLinkColor : notActiveLinkColor,
                        })}
                    >
                        Play
                    </NavLink>
                    <NavLink
                        to="/leaderboard"
                        style={({ isActive }) => ({
                        color: isActive ? activeLinkColor : notActiveLinkColor,
                        })}
                    >
                        Leaderboard
                    </NavLink>
                    <NavLink
                        to="/about"
                        style={({ isActive }) => ({
                        color: isActive ? activeLinkColor : notActiveLinkColor,
                        })}
                    >
                        About
                    </NavLink>
                    <a href="https://docs.longgame.wtf" target="_blank" rel="noopener noreferrer" >
                        Docs
                    </a>
                    </HStack>
                </Flex>
                <Flex>
                <Topbuttons/> 
                <Social direction="row"></Social>
                <BackgroundAudio src={BbAudio} />
                </Flex>
            
                
            </Flex>:
                <Flex bg='brand.400' textColor='brand.100'
                        align='center' gap='2' width="100%" height="50px" justify="space-between">
                    <Button
                         display={{ base: "inline-flex", md: "none" }} // Show only on small screens
                         onClick={onOpen}
                         aria-label="Open menu"
                         size="md"
                         backgroundColor="brand.400"
                    >
                        <Image src={MenuHamgurgerIcon} boxSize="24px"/>
                        
                    </Button>
                    <Image disply="in-line" src={Logo} mx={6} width="92px" height="24px" objectFit='contain'></Image>
                <Flex>
                <Topbuttons/> 
                <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
                    <DrawerOverlay/>
                    <DrawerContent backgroundColor="brand.800" color="brand.100">
                    <DrawerCloseButton >
                        <Image src={MenuCloseIcon} boxSize="20px"/>
                    </DrawerCloseButton>
                   
                        <DrawerHeader>
                            <Center h="42px">
                                <Image  src={Logo} w="92px" h="24px" objectFit='contain'></Image>
                            </Center>
                        </DrawerHeader>
                    
                        <DrawerBody mt={10}>
                            <Flex direction="column" fontSize="md" fontWeight="semibold" justify="center">
                                
                                <VStack align="start" spacing={10}>
                                    <Link to="/" onClick={onClose}>Home</Link>
                                    <Link to="/play" onClick={onClose}>Play</Link>
                                    <Link to="/leaderboard" onClick={onClose}> Leaderboard </Link>
                                    <Link to="/about" onClick={onClose}> About </Link>
                                    <a href="https://docs.longgame.wtf" target="_blank" rel="noopener noreferrer" >
                                            Docs
                                    </a>
                                    <Social direction="column" />
                                    <BackgroundAudio src={BbAudio} showText={true} />
                                </VStack>
                            </Flex>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>

                
                </Flex>
                
            </Flex>}
        </Box>
         


    );
}