import { Image, Flex, Text, Button, Skeleton, Box, HStack ,  Center, VStack, 
    Input,
    useClipboard,
    useToast, Link, Icon} from "@chakra-ui/react";
import { InputGroup, IconButton, InputRightElement } from "@chakra-ui/react";
import { useState, useEffect } from 'react'
import TwitterIcon from "../images/twitter.svg"
import { textShadowValue } from "../utils/constant"
import {  gql, useMutation, useLazyQuery } from '@apollo/client';
import { backendClinet, graphQLClient } from '../graphql/apollo-client';
import { useAccount } from 'wagmi';
import { LongGameID, DiscordURL } from "../utils/constant";
import { FaRegCopy } from "react-icons/fa";
import { FaDiscord } from 'react-icons/fa';
import { FaUserFriends } from "react-icons/fa";
import { ReferralModal } from "./ReferralModal";

const GET_OR_GETENERATE_REFERRAL_CODE = gql`
    mutation generateReferralCodeForLongGamer($userId: ID!, $appId: ID!) {
        generateReferralCodeForLongGamer(userId: $userId, appId: $appId)
  }
`;


const GET_REFERRALS = gql`
    query MyQuery($code : String!) {
        referrals(where: {referredBy: $code}, orderBy: "joinedAt") {
        items {
            joinCount
            joinedAt
            participant
            referredBy
            id
        }
        }
    }
`;

export function ShareTwitter() {

    // wallet state
    const { address } = useAccount();

    // referral code stats
    const [showCopied, setShowCopied] = useState(false);
    const [code, setCode] = useState('')

    // modal state
    const [ openReferralModal, setOpenReferralModal] = useState(false)
    
    // referral state
    const [referrals, setReferrals] = useState([])

    const getUrlLink = () => {
        if(code.length === 5 ) {
            return `https://longgame.wtf/#/play?referral=${code}`
        } else {
            return "https://longgame.wtf"
        }
    }
    const { onCopy } = useClipboard(getUrlLink());

    const text = "Join Long Game -- you'll 2x of your bet and win Blast Gold!"
    const hashtags = "LONGGAME"
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(getUrlLink())}&hashtags=${encodeURIComponent(hashtags)}`;

    const [generateCode, {loading, error, data}] = useMutation(GET_OR_GETENERATE_REFERRAL_CODE, {
        //variables: { userId: address, appId: LongGameID },
        client: backendClinet,
    })

    const [getReferrals, { loading: loadingReferral, data: referralData, error: referralError }] = useLazyQuery(GET_REFERRALS, {
        client: graphQLClient
    });

    /** Get referral code for user */
    useEffect(() => {
        if(address && code.length === 0  ) {
            generateCode({ variables: { userId: address, appId: LongGameID } });
        }
    }, []);

    useEffect(() => {
        if(data && data.generateReferralCodeForLongGamer ) {
            setCode(data.generateReferralCodeForLongGamer)
        }
    }, [data]);
    
    /* Get referrals*/  
    useEffect(() => {
        if(address && code.length === 5  ) {
            getReferrals({ variables: { code: code } });
        }
    }, [code]);

    useEffect(() => {
        if(referralData && referralData.referrals?.items ) {
            setReferrals(referralData.referrals?.items)
        }
    }, [referralData]);


    const handleCopy = () => {
        onCopy();
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 2000);
       
      };
    
    const handleReferralModalClose = () => {
        setOpenReferralModal(false)
    }

    const handleShare = () => {
        window.open(twitterUrl, "_blank", "noopener,noreferrer");
    };

    return(
        <Flex  direction="column" justifyContent="center" alignContent="flex-start"
            mx={6}
            mt={6}
            pb={8}
        >
            {openReferralModal && 
                <ReferralModal 
                    handlClose={handleReferralModalClose}
                    referrals={referrals}
                />  
            }
            <Flex direction="row" justify="space-between" align="center">
                <Text fontSize={{base: "md", md: "lg"}} color="brand.100" 
                    fontWeight="semibold" alignItems="left"
                    textShadow={textShadowValue}
                >
                    Refer Friends
                </Text>
                <HStack>
                    <FaUserFriends  boxSize="24px" color="#3E95E5"/>
                        <Button  onClick={()=> setOpenReferralModal(true)} disabled={! (address) }
                            variant="link" color="#3E95E5" fontSize={{base: "sm", md: "md"}}  fontWeight="semibold"
                        >
                            Referrals
                        </Button>
                </HStack>
            </Flex>
            <Box mt="6">
                <Flex direction="column">
                    <HStack>
                        <Box borderRadius="50%" border="1px" borderColor="brand.700" boxSize="24px">
                            <Text fontSize="sm" fontWeight="semibold" color="brand.100" align="center" textShadow={textShadowValue}>1</Text>
                        </Box>
                        <Box>
                            <Text fontSize="sm" fontWeight="semibold" color="brand.700" align="left">
                                Join Long Game now
                            </Text>
                            <Text fontSize="xs" color="brand.700" >
                                Play the game to get your referral code or ask in Discord
                            </Text>
                        </Box>
                        
                    </HStack>
                    <Box width="1px" height="24px" border="1px" borderColor="brand.700" position="relative" left="11px"/>
                    <HStack> 
                        <Box borderRadius="50%" border="1px" borderColor="brand.700" boxSize="24px">
                            <Text fontSize="sm" fontWeight="semibold" color="brand.100" align="center" textShadow={textShadowValue}>2</Text>
                        </Box>
                        <Box>
                            <Text fontSize="sm" fontWeight="semibold" color="brand.700" align="left">
                            Share Your Referral Code
                            </Text>
                            <Text fontSize="xs" color="brand.700">
                            Get and share your unique code with friends and communities
                            </Text>
                        </Box>
                    </HStack>
                    <Box width="1px" height="24px" border="1px" borderColor="brand.700" position="relative" left="11px"/>
                    <HStack> 
                        <Box borderRadius="50%" border="1px" borderColor="brand.700" boxSize="24px">
                            <Text fontSize="sm" fontWeight="semibold" color="brand.100" align="center" textShadow={textShadowValue}>3</Text>
                        </Box>
                        <Box>
                            <Text fontSize="sm" fontWeight="semibold" color="brand.700" align="left">
                                Reap the Rewards
                            </Text>
                            <Text fontSize="xs" color="brand.700">
                                2x Your Bet faster or win extra rewards each round
                            </Text>
                        </Box>
                    </HStack>
                </Flex>
            </Box>
            <Skeleton isLoaded={!loading} startColor='0x080808e1' endColor='0x080808e1' mt="6">
                <Box borderWidth={1} borderStyle="dashed" px={2} borderColor="brand.700" >
                {code.length === 5 ? 
                <InputGroup height={10}>
                    <Input
                        variant='unstyled' borderRadius={0} border="1px"
                        placeholder='Your code'
                        size='sm' bgColor="brand.800"
                        value={`https://longgame.wtf/#/play?referral=${code}`}
                        color="brand.500"
                        borderColor="transparent"
                        isReadOnly
                        
                    />
                    <InputRightElement>
                        <Button onClick={handleCopy} variant="ghost" color="brand.700" width="52px" height="16px"
                         _hover={{ bg: 'transparent'}}
                         _active={{ bg: 'transparent' }}
                         _focus={{ bg: 'transparent' }}
                        >
                            <Box width="52px" height="100%" align="center">
                            {showCopied ? 'Copied' : <FaRegCopy size={16} />}
                            </Box>
                        </Button>
                    </InputRightElement>
                </InputGroup>:
                <Center height={10} >

                <Text color="brand.700" fontSize="sm" >
                    Join Long Game or ask in {' '}
                    <Link
                        href={DiscordURL}
                        isExternal
                        color="brand.1000"
                        fontWeight="medium"
                        _hover={{ textDecoration: 'underline', color: 'brand.200' }}
                    >
                        <Icon as={FaDiscord} mr={1} color="brand.discord" />
                        {' Discord '}
                    </Link>
                    for your referral code

                </Text>
                </Center>
                }
            </Box>

            

            </Skeleton>
           
            <Button  
                onClick={handleShare}
                variant="custom" fontSize={{base: "md", md:"lg", '2xl': "xl"}}
                width="100%"
                margin="auto"
                mt={6}
                leftIcon={<Image src={TwitterIcon} boxSize="24px"/>}
                            >
                SHARE
            </Button>
            

        </Flex>
    );
}