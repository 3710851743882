import {  Box, useMediaQuery} from "@chakra-ui/react";
import { ConnectButton } from '@rainbow-me/rainbowkit';

export const Topbuttons = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  
  const connectButton = () => {
      return (
      <ConnectButton 
          accountStatus={{
            smallScreen: 'address',
            largeScreen: 'full',
          }}
        />
      )
  }
  return (
    <Box pr={5}>
      {isLargerThan768?
      <Box  boxShadow="-2px 2px 6px 0px #ACFF01">
        {connectButton()}
      </Box>:
      <Box>
        {connectButton()}
      </Box>
    }
    </Box>

  );
};