import { VStack, Flex, Image, useMediaQuery, HStack, Text, Center } from "@chakra-ui/react";

import Bb2 from '../images/header-bg2.png'
import ScrollDownIcon from '../images/down.png'
import { AnimatedBox } from "../AnimateComponents/AnimatedBox";
import { AnimationBouncing } from "../AnimateComponents/AnimationBouncing";
import { textShadowValue } from "../utils/constant";

export function AboutLongGame() {
    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
    const distance = isLargerThan768? -300: -100
    return(
  
            
            <Flex bgImage={`url(${Bb2})`}  
                width="100%" 
                bgPosition="center"
                backgroundRepeat="no-repeat"
                bgSize="cover"
                direction="column" 
                justifyContent="space-between"
                overflow="hidden"
               
            >
                <Flex direction="column" justifyContent="center" alignItems="center" height="80vh" width="100%" m="auto">
                    <AnimatedBox distance={distance}>
                        <Text color="brand.100" fontWeight="semibold" 
                            fontSize={{base:"2xl", md: "4xl"}}
                            textShadow={textShadowValue}
                        > 
                            Play, Win, Earn
                        </Text>
                    </AnimatedBox>
                    <Flex justify="center" direction="column"
                        maxW="1000px"
                        width="90%"
                        fontSize={{base:"md", md: "lg"}} 
                        color="brand.500" 
                        fontWeight="semibold"
                        align="center"
                        px={{base: 5, md: 30}}
                    >
                    <AnimatedBox distance={distance * -1}>

                        <Text mt={8} >
                            Welcome to the Long Game, the ultimate on-chain gaming experience where every move is a chance to da moon! 
                        </Text>
                        <Text mt={8} 
                        >
                            Forget the house edge—here, we play by degen rules where every move is transparent and pays off. Whether you snag the win or not, we’ve got your back with rewards that keep on giving. It’s all about sticking around, 'cause the longer you’re in, the more you stack up. 
                        </Text>
                    
                    </AnimatedBox>
                    </Flex>
                </Flex>
                <AnimationBouncing>
                <VStack 
                    textAlign="center"
                    width="100%"
                    height="20vh"
                >
                    <Text color="brand.300"> SCROLL</Text>
                    <Image src={ScrollDownIcon} alt='scroll-down' boxSize="24px" objectFit='cover'/>
                </VStack> 
                </AnimationBouncing>
  
            </Flex>
   


        
    );
}