import { Button, Box, Flex, Text, useMediaQuery, Image, VStack, Divider, Center,
    Table, Thead, Tbody, Tr, Th, Td,

} from "@chakra-ui/react";
import { textShadowValue } from "../utils/constant"
import { CloseIcon } from '@chakra-ui/icons'
import {motion} from "framer-motion"
import { useAccount } from 'wagmi';
import { Backdrop } from "./Backdrop"
import AvatarIcon from "../images/avatar.svg"
import { useState, useEffect } from 'react'
import { formatAvatarUrl } from '../utils/utils';
import { entryFee, payoutRate } from "../utils/constant";
import { RoundStatus } from "./Activities";
import { RaindropAnimation } from "../AnimateComponents/RaindropAnimation";

export function ClaimModal({handlClose, onClaimToken, claimInfo, claiming, claimTxInfo}) {

    const { address } = useAccount();
    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
    const dropIn = {
        hidden: {
            y: "100vh"
        },
        visible: {
            y: "0",
            transition: {
                duration: 2,
                type: "spring",
                damping: 88,
                stiffness: 500

            }
        },
        exit: {
            y:" 100vh",

        }
    }

    return (
        <Box>
            {isLargerThan768 ? 
        <Backdrop onClick={handlClose}>
            {claimTxInfo === "Succeed" && <RaindropAnimation /> }
            <Center>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={dropIn}
            >
                <Flex 
                    direction="column" 
                    height="100%"
                    maxH="60vh"
                    top="20vh"
                    width={{base: "95%", md: "90%", xl: "1000px"}} 
                    position="relative" 
                    justifyContent="center" 
                    align="center" mt="10%"
                    backgroundColor="brand.800"
                    p={20}
                >
                     <CloseIcon onClick={handlClose}
                        boxSize="12px" color="brand.700" position="absolute" top="5%" right="5%"/>
                        <Text fontWeight="semibold" fontSize={{base: "lg", md: "2xl"}} color="brand.100" 
                                textShadow={textShadowValue}
                                pt={4} 
                        >
                            Claim $Long Reward
                        </Text>
                        <Text fontSize={{base: "sm", md: "md"}} color="brand.500"  mt={{base: "4", md: "8"}} 
                            fontWeight="semibold"
                            >
                            Keep $Long 🚀 
                            While luck wasn’t on your side this round, your dedication hasn't gone unnoticed. 
                            Here are your $Long tokens, rewarding your stay and effort. 
                            Remember, the longer you play, the more you earn. 
                            Your loyalty transforms every game into a win!
                
                        </Text>
                        <Button  
                                onClick={()=> onClaimToken(claimInfo?.round)}
                                variant="custom" fontSize={{base: "sm", md:"lg"}}
                                width="60%"
                                mt={8}
                                isLoading={claiming}
                                loadingText={claimTxInfo}
                                spinnerPlacement='start'
                                isDisabled={claimTxInfo === "Succeed"}
                                >
                                {claimTxInfo !== "Succeed" ? "Claim Now" : claimTxInfo }
                        </Button>
                    </Flex>
            </motion.div>
            </Center>
        </Backdrop>
        :
        <motion.div
        onClick={handlClose}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={dropIn}
        style={{ width: "100vw", height: "100vh", 
            position: "fixed", bottom: 0, left: 0, backgroundColor: "brand.800", zIndex: 200 }}
    >
        {claimTxInfo === "Succeed" && <RaindropAnimation /> }
        <Flex 
            direction="column" 
            height="100%"
            justifyContent="center" 
            align="center" 
            backgroundColor="brand.800"
            p={6}
            
        >
             <CloseIcon onClick={handlClose}
                boxSize="12px" color="brand.700" position="absolute" top="5%" right="5%"/>
                <Text fontWeight="semibold" fontSize={{base: "lg", md: "2xl"}} color="brand.100" 
                        textShadow={textShadowValue}
                        pt={4} 
                >
                    Claim $Long Reward
                </Text>
                <Text fontSize={{base: "sm", md: "md"}} color="brand.500"  mt={{base: "4", md: "8"}} 
                    fontWeight="semibold"
                    >
                    Keep $Long 🚀 
                    While luck wasn’t on your side this round, your dedication hasn't gone unnoticed. 
                    Here are your $Long tokens, rewarding your stay and effort. 
                    Remember, the longer you play, the more you earn. 
                    Your loyalty transforms every game into a win!
        
                </Text>
                <Button  
                        onClick={()=> onClaimToken(claimInfo?.round)}
                        variant="custom" fontSize={{base: "sm", md:"lg"}}
                        width="90%"
                        mt={8}
                        isLoading={claiming}
                        loadingText={claimTxInfo}
                        spinnerPlacement='start'
                        isDisabled={claimTxInfo === "Succeed"}
                        >
                        {claimTxInfo !== "Succeed" ? "Claim Now" : claimTxInfo }
                </Button>
            </Flex>
    </motion.div>
        }
        </Box>
    )

}