import { VStack, Flex, Image, Box, HStack, Text, Center } from "@chakra-ui/react";

import Bb2 from '../images/header-bg2.png'
import ScrollDownIcon from '../images/down.png'
import { AnimatedBox } from "../AnimateComponents/AnimatedBox";
import { AnimationBouncing } from "../AnimateComponents/AnimationBouncing";
import { textShadowValue } from "../utils/constant"
export function OurTeam() {
    return(
  
            
            <Flex bgImage={`url(${Bb2})`}  
                width="100%" 
                bgPosition="center"
                backgroundRepeat="no-repeat"
                bgSize="cover"
                direction="column" 
                justifyContent="space-between"
                overflow="hidden"
               
            >
                <Flex direction="column" justifyContent="center" alignItems="center" height="80vh" width="100%" >
                    <AnimatedBox distance={-100}>
                        <Text color="brand.100" fontWeight="semibold" fontSize={{base:"2xl", md: "4xl"}}
                            textShadow={textShadowValue}
                        > Our Team</Text>
                    </AnimatedBox>
                    <Flex justify="center" direction="column"
                        maxW="1000px"
                        width="90%"
                        fontSize={{base:"md", md: "lg"}} 
                        color="brand.500" 
                        fontWeight="semibold"
                        align="center"
                        px={{base: 5, md: 30}}
                    >
                        <AnimatedBox distance={300}>
                        <Text pt={10} >
                        At the core of Long Game is a team of cryptonative experts who are deeply invested in the long-term potential of blockchain technology. 
                        We are builders and investors with our skin firmly in the game, committed to developing sustainable, impactful projects. 
                        </Text>
                        <Text pt={10} >
                        Our greatest realization has been that true success and fulfillment come from staying committed to our beliefs over the long haul. 
                        This philosophy has not only yielded significant returns but also fostered a thriving community that shares our vision. 
                        </Text>
                        </AnimatedBox>
                    </Flex>
                </Flex>

  
            </Flex>
   


        
    );
}