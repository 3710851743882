export const zootoolForm = "https://form.zootools.co/go/aZ80dhmAMR9HyZNV9xGb"

export const gameDuration = 24 * 60 * 60 * 1000;

export const gameDurationText = "24 hour"

export const entryFee = 0.01;

export const payoutRate = 2;

export const textShadowValue = "2px 2px 10px rgba(172, 255, 1, 0.7)"

export const AlertStatus = Object.freeze({
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
});

export const LongGameID = process.env.REACT_APP_LONG_GAME_ID;

export const DiscordURL = "https://discord.gg/WjVMH34ZF7"