import {  Tooltip, Box } from "@chakra-ui/react";

export function GameTooltip({children, message}) {

    return(
        <Tooltip hasArrow placement="right-end" bg='brand.100' color='brand.200'
            label={message}
           >
            {children}
        </Tooltip>
    );
}