import { Box, HStack, Flex, Text, Image, Center } from "@chakra-ui/react";
import { useQuery, gql } from '@apollo/client';
import { formatAddress, formatAvatarUrl } from "../utils/utils";
import { formatDistanceToNow } from 'date-fns';
import { entryFee, payoutRate } from "../utils/constant";
import { useState, useEffect, useRef } from 'react'
import { AnimatedPlayerList } from "../AnimateComponents/AnimatedPlayerList";
import { WinModal} from "../Game/WinModal"
import { useAccount } from 'wagmi';
import { graphQLClient } from './apollo-client';



/** Return data example
  {
    "createdAt": 1715129208,
    "id": "1+3", // position+round
    "position": 1,
    "participant": "0x4F96AB31bbbdb65FBac48Bca54304BF64caDdaCD",
    "roundId": 3
  },
 * 
 */


const GET_INITIAL_PAYOUTS = gql`
  query GetPayoutsQuery {
    payouts(orderBy: "lastExitedAt", orderDirection: "desc", limit: 8) {
        items {
            createdAt
            exitedPositionCnt
            participant
            id
            lastExitedAt
            positions
            roundId
        }
      }
  }
`;

const GET_MORE_PAYOUT = gql`
  query GetPayoutsQuery($ts: Int!)  {
    payouts(orderBy: "lastExitedAt", orderDirection: "desc", limit: 8, where: {lastExitedAt: $ts}) {
      items {
        createdAt
        exitedPositionCnt
        participant
        id
        lastExitedAt
        positions
        roundId
      }
    }
  }
`

export function RecentPayout({isGameEnded, currentRound}) {
  const { address } = useAccount();

  const [payouts, setPayouts] = useState([])   
  const payoutsRef = useRef(payouts)
  const [fetchSince, setFetchSince] = useState(Math.floor(new Date()/1000))
  const [totalFetched, setTotalFetched] = useState(0)

  //const [lastWinModalTime, setLastWinModalTime] = useState(0)
  //const lastWinModalTimeRef = useRef(lastWinModalTime)

  //const [showWinModal, setShowWinModal] = useState(false)
  //const showWinModalRef = useRef(showWinModal)

  const {loading, error, data} = useQuery(GET_INITIAL_PAYOUTS, {client: graphQLClient})
  const {loading: loadingMore, error: moreError, data: pollingData} = useQuery(GET_MORE_PAYOUT, {
      variables: { ts: fetchSince },
      pollInterval: 20000 , // Poll every 20 seconds
      client: graphQLClient,
  })

  useEffect(() => {
    if (data && data.payouts && data.payouts.items && data.payouts.items.length > 0 ) {
      setFetchSince(Math.floor(new Date()/1000))
      const transformedPayouts = transformList(data.payouts.items)
      setPayouts(transformedPayouts)
      payoutsRef.current = transformedPayouts
      setTotalFetched(data.payouts.items.length)
    }
  }, [data]);

  useEffect(() => {
      if (pollingData && pollingData.payouts && pollingData.payouts.items.length > 0 ) {
        const newPayouts = transformList(pollingData.payouts.items)
        setFetchSince(Math.floor(new Date()/1000))
        console.log('recent payouts v2 new payouts: ', newPayouts, payouts)
        const mergedList = [...newPayouts, ...payouts].slice(0,8)
        setPayouts(mergedList)
        payoutsRef.current = mergedList
        setTotalFetched(totalFetched + newPayouts.length)
      }
    }, [pollingData]);  

 /*
  useEffect(() => {
    const interval = setInterval(() => {
      const myPayouts = 
        payoutsRef.current.filter(p=> p.participant === address && p.timestamp > lastWinModalTime)
                          .sort((a,b) => b.lastExitedAt - a.lastExitedAt)
      if(myPayouts.length > 0 
          && myPayouts[0].roundId === currentRound 
          && myPayouts[0].timestamp > lastWinModalTimeRef.current){
        setShowWinModal(true)
        setLastWinModalTime(myPayouts[0].timestamp)
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [isGameEnded]);

  
  useEffect(() => {
    // Update the ref's current value whenever the state changes
    showWinModalRef.current = showWinModal
    lastWinModalTimeRef.current = lastWinModalTime
  }, [showWinModal, lastWinModalTime]);
 

  const handleWinModalClose = () => {
    setShowWinModal(false)
  }
  */

  const transformList = (list) => {
    return list.map( l => ({
      participant: l.participant,
      timestamp: l.lastExitedAt,
      amount: payoutRate * entryFee * l.exitedPositionCnt,
      roundId: l.roundId
    }))
  }

    return(
        <Box>
          
          {}
            {loading && <Text color="brand.700">Loading...</Text>}
            {error && <Text color="brand.700">Error...</Text>}
            
            { payouts? 
              <Flex direction="column" justify="flex-start" mt={3}> 
                < AnimatedPlayerList totalFetched={totalFetched} list={payouts}/>
              </Flex>
                :
              <Center>
                  <Text fontSize={{base: "xs", md: "md"}} color="brand.700" fontWeight="semibold" mt={6}>
                      No Activities
                  </Text>
              </Center>
            }
        </Box>
    )
}

