import { Box, Skeleton, Flex, Text, SimpleGrid, Image, VStack, Divider, Center } from "@chakra-ui/react";
import { useState, useEffect, useRef } from 'react'
import { AnimationBouncing} from "../AnimateComponents/AnimationBouncing"
import { useQuery, gql } from '@apollo/client';
import { textShadowValue } from "../utils/constant";
import { gameDuration } from "../utils/constant";
import { AnimatedNumber } from "../AnimateComponents/AnimatedNumber"
import { AnimatedCounter } from "../AnimateComponents/AnimatedCounter"
import { graphQLClient } from '../graphql/apollo-client';



/**
 * "data": {
    "game": {
      "totalPositions": 8,
      "nextPositionToExit": 1
    }
  }
 */
const GET_GAME_DATA = gql`
  query GameQuery($id: Int!) {
    game(id: $id) {
        id,
        totalPositions, 
        nextPositionToExit, 
        lastJoinedAt
      }
  }
`;

export function GameStats({roundId, endTime, isGameEnded}) {
    const [timeLeft, setTimeLeft] = useState({
        hours: '00',
        minutes: '00',
        seconds: '00',
      });
    const [timeReady, setTimeReady] = useState(false) // timeleft is not ready 

    // game state
    const [targetDate, setTargetDate] = useState(endTime)
    const [totalPlayers, setTotalPlayers] = useState(0)
    const prevTotalPlayersRef = useRef();
    prevTotalPlayersRef.current = 0

    const {loading, error, data} = useQuery(GET_GAME_DATA, {
        variables: { id:  roundId },
        pollInterval: 10000 , // Poll every 10 seconds
        client: graphQLClient
    })

    useEffect(() => { 
        if(data && data.game) {
            setTargetDate(new Date(Math.floor(data.game.lastJoinedAt/1)*1000 + gameDuration))
            setTotalPlayers(data.game.totalPositions)
            prevTotalPlayersRef.current = data.game.totalPositions
        }
    }, [data])

    const prevTotalPlayers = prevTotalPlayersRef.current;

    useEffect(() => {
        const interval = setInterval(() => {
          const now = new Date();
          const difference = targetDate - now.getTime();
          const timeLeft = difference > 0 ? calculateTimeLeft(difference) : { hours: '00', minutes: '00', seconds: '00' };
    
          setTimeLeft(timeLeft);
          setTimeReady(true);
        }, 1000);
    
        return () => clearInterval(interval);
      }, [targetDate]);

    const calculateTimeLeft = (difference) => {
        let timeLeft = {};

        timeLeft.hours = Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');
        timeLeft.minutes = Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0');
        timeLeft.seconds = Math.floor((difference / 1000) % 60).toString().padStart(2, '0');
        
        return timeLeft;
    };

    return(
        !isGameEnded? (
            <VStack>
                <Flex direction="row" color="brand.100" 
                        fontSize={{ base: "lg", md: "xl", "2xl": "2xl"}} 
                        fontWeight="bold"
                        textShadow={textShadowValue}>
                        Round 
                    <AnimatedCounter styles={{ ml: 2 }}from={0} to={roundId} />
                </Flex>
                <Skeleton isLoaded={!loading} 
                    startColor='brand.400' endColor='brand.400'
                >
                    <Flex direction="row" mt={{base:0, md: 2}} justifyContent="center" alignItems="center" minHeight="100px">
                     <VStack direction="column" width="200px">
                        <Text fontSize={["sm", "md", "md"]} color="brand.700"  >
                            Total Players
                        </Text>
                        <Box fontWeight="semibold" fontSize={{ base: "lg", md: "xl", "2xl": "2xl"}}  color="brand.100" textShadow={textShadowValue}>
                            <AnimatedCounter from={prevTotalPlayers} to={totalPlayers} 
                            />
                        </Box>
                        
                    </VStack>
                    <VStack direction="column" width="200px">
                        <Text fontSize={["sm", "md", "md"]} color="brand.700" >
                        Game End In:
                        </Text>
                        <Text fontWeight="semibold" fontSize={{ base: "lg", md: "xl", "2xl": "2xl"}}  color="brand.100" textShadow={textShadowValue}>
                        {`${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}
                        </Text>
                    </VStack>
                    </Flex>
                 </Skeleton>
            </VStack>
        ) : 
            <AnimationBouncing>
                <Center>
                <VStack mt={2} minHeight="100px">
                    <Text fontWeight="semibold" fontSize={{ base: "lg", md: "xl", "2xl": "2xl"}}  color="brand.100" textShadow={textShadowValue}>
                        First Move, First to Triumph!
                    </Text>
                    <Text fontWeight="semibold" fontSize={{ base: "md", md: "lg", "2xl": "xl"}}  color="brand.500" textShadow={textShadowValue}>
                        Your Odds to Double Up Are Unmatched
                    </Text>
                </VStack>
                </Center>
            </AnimationBouncing>

    )
}

