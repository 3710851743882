import { Box, HStack, Flex, Text, useMediaQuery, Tooltip, VStack, Grid, Center, GridItem,
    Image

} from "@chakra-ui/react";
import { useState, useEffect } from 'react'
import { CloseIcon } from '@chakra-ui/icons'
import {motion} from "framer-motion"
import { useAccount } from 'wagmi';
import { Backdrop } from "./Backdrop"
import { entryFee, payoutRate, textShadowValue } from "../utils/constant";
import { useNativeCurrency } from '../providers/NativeCurrencyProvider'; // Adjust the import path as needed
import ETHIcon from '../images/eth.svg'
import { HoverBox } from "./HoverBox";
import { formatAvatarUrl } from "../utils/utils";
import CelebrationAnimation  from "../AnimateComponents/CelebrationAnimation"

export function UserDetails({handlClose, currentRoundInfo, currentGameInfo}) {

    const { address } = useAccount();
    const nativeCurrencySymbol = useNativeCurrency();


    // component state
    const [ totalPositions, setTotalPositions] = useState(0)
    const [ nextPositionToExit, setNextpositionToExit] = useState(0)
    const [ exitedPositionCnt, setExitedPositionCnt] = useState(0)
    const [ hold, setHold] = useState([])
    const [ currentRoundId, setCurrentRoundId] = useState(0)

    // ui stats
    const dropIn = {
        hidden: {
            y: "100vh"
        },
        visible: {
            y: "0",
            transition: {
                duration: 2,
                type: "spring",
                damping: 88,
                stiffness: 500,
            },
            zIndex: 1000
        },
        exit: {
            y:" 100vh"
        }
    }

    const smBarWidth = 300
    const mdBarWidth = 500
    const lgBarWidth = 800

    // Current round info of the user and game info

    useEffect(() => {
        const exitedPositionCnt = currentRoundInfo.exitedPositionCnt
        const holdPositions = currentRoundInfo.positions.slice(exitedPositionCnt, currentRoundInfo.positions.length)
        const hold = convertToChunks(holdPositions)

        setCurrentRoundId(currentRoundInfo.roundId)
        setTotalPositions(currentGameInfo.totalPositions)
        setNextpositionToExit(currentGameInfo.nextPositionToExit)
        setExitedPositionCnt(exitedPositionCnt)
        setHold(hold)

    }, [currentRoundInfo, currentGameInfo]);

    function convertToChunks(arr) {
        if (arr.length === 0) return [];
        
        const chunks = [];
        let start = arr[0];
        let length = 1;
        
        for (let i = 1; i < arr.length; i++) {
            if (arr[i] === arr[i - 1] + 1) {
            length++;
            } else {
            chunks.push([length, start]);
            start = arr[i];
            length = 1;
            }
        }
        
        // Push the last chunk
        chunks.push([length, start]);
        
        return chunks;
        }


    return (
        <Box>
           
            <Backdrop onClick={handlClose}>
            <Center>
                <motion.div
                    onClick={(e) => e.stopPropagation()}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={dropIn}
                    style={{
                        base: {
                            bottom:0, left: 0, position: 'fixed'
                        },
                        md: {}
                    }}
                >
                    <Flex 
                        direction="column" 
                        height={{base:"100vh", md:"100%" }}
                        width={{base: "100vw", md: "700px", xl: "1000px"}} 
                        position="relative"
                        justifyContent="center" 
                        align="center" mt={{base:"0", md:"10%" }}

                        backgroundColor="brand.800"
                        p={6}
                    >
                        <CloseIcon onClick={handlClose}
                            boxSize="12px" color="brand.700" position="absolute" top="5%" right="5%"/>
                        <Text fontWeight="semibold" fontSize={{base: "lg", md: "xl"}} color="brand.100" 
                                textShadow={textShadowValue}
                        >
                             Round {currentRoundId}
                        </Text>
                    

                        {  totalPositions && nextPositionToExit  && hold &&
                            <Box height="200px" mt={16}>
                                <HStack position="relative" left={{
                                    
                                    base: `${nextPositionToExit * smBarWidth / totalPositions}px`,
                                    md: `${nextPositionToExit * mdBarWidth / totalPositions}px`
                                }}>
                                    <Box border="1px" color="brand.700" height="40px" width="2px"
                                    />
                                    <Text color="brand.500" fontWeight="semibold" fontSize={{base: "sm", md:"md"}} width={{base:"150px", md: " 350px"}} mb={6} alignContent="baseline"> 
                                        {`Player at the position ${nextPositionToExit} is the next to exit`}
                                    </Text>
                                </HStack>
                                <Box   
                                    color="brand.100" 
                                    borderRadius="10px"
                                    bgColor="brand.700"
                                    width={{
                                        base: `${smBarWidth}px` ,
                                        md: `${mdBarWidth}px` 
                                    }}
                                    height="20px"
                                    >
                                    <Box   
                                        color="brand.100" 
                                        borderRadius="10px"
                                        bgColor="brand.100"
                                        width={{
                                            base: `${nextPositionToExit * smBarWidth / totalPositions}px`, 
                                            md: `${nextPositionToExit * mdBarWidth / totalPositions}px`
                                        }} 
                                        height="20px"
                                    >
                                    
                                        {exitedPositionCnt > 0 &&
                                            <HStack position="relative" left="0">
                                                <HoverBox message={ 
                                                    `You have exited ${exitedPositionCnt} positions, earned ${exitedPositionCnt * payoutRate * entryFee} ${nativeCurrencySymbol}`} 
                                                >
                                                    <Box 
                                                        height="30px" width="30px" alignContent="baseline" position="relative" left="50px">
                                                        <Image  src={ETHIcon} boxSize="20px"/>
                                                    </Box>   

                                                </HoverBox>
                                            </HStack>
                                        }
                                        { exitedPositionCnt > 0 && <CelebrationAnimation />}
                                    </Box>
                                    <Box>
                                        {
                                            hold.map( (h, index) => (
                                                <HoverBox as="span" key={index} message={`You are holding ${h[0]} positions, starting at position ${h[1]}`}>
                                                    <Image  src={formatAvatarUrl(address)} boxSize="24px"   position="relative" 
                                                        left={{
                                                            base: `${(h[1] * smBarWidth/ totalPositions) -6 }px`,
                                                            md: `${(h[1] * mdBarWidth/ totalPositions)-6 }px`
                                                        }}
                                                        top={`${-(index+1) * 24}px`}
                                                        /> 
                                                </HoverBox>
                                            ))
                                        }

                                    
                                    </Box>
                                </Box>
                            </Box>
                        }
                        
                        <Text as="i" color="brand.700" fontSize="sm" mt={10}>
                            *Hover over the queue progress icon to see details
                        </Text>


                       
                       

                    </Flex>
                    
                </motion.div>
            </Center>
            </Backdrop>
       

        </Box>
        
    )

}