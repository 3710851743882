import { Box, Center, Text, VStack, useMediaQuery } from "@chakra-ui/react";
import { Backdrop } from "./Backdrop"
import { useState, useEffect } from 'react'
import { motion } from "framer-motion"
import { CloseIcon } from '@chakra-ui/icons'
import TopBorder from "../images/top-border-green.svg"
import BottomBorder from "../images/bottom-border-green.svg"
import CelebrationAnimation  from "../AnimateComponents/CelebrationAnimation"
import { AnimatedProgress } from "../AnimateComponents/AnimatedProgress"
import { textShadowValue } from "../utils/constant";



export function InfoCard({message}) {

    const boxSize="30px"
    
    return (
        <Box position="relative" width="300px" height="50px"> 
            <Box
                    as="span"
                    position="absolute"
                    top="0"
                    left="0"
                    boxSize={boxSize}
                    bgImage={`url(${TopBorder})`} 
                    backgroundRepeat="no-repeat"
                    bgSize="cover"
                />

                    <Text position="relative" left={boxSize} top="20px" 
                        color="brand.500" fontSize="sm" width="100%" fontWeight="semibold" >
                        {message}
                    </Text>
                <Box
                    position="relative"
                    right="-300px"
                    bottom="0"
                    boxSize={boxSize}
                    backgroundImage={`url(${BottomBorder})`}
                    backgroundRepeat="no-repeat"
                    bgSize="cover"
                />
        </Box>
    )

}