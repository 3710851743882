import React, { useState, useRef} from 'react';
import { FaEthereum, 
    FaRegListAlt, 
    FaRegGem, 
    FaRegClock, 
    FaExchangeAlt, 
    FaHistory, 
    FaRegSmileBeam,
    FaAngleDoubleUp,
    FaShareAlt  } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { entryFee, gameDurationText } from '../utils/constant';
import {
  Box,
  Text,
  Flex,
  List,
  ListItem,
  ListIcon
} from '@chakra-ui/react';
import TopBorder from '../images/top-border.svg'
import BottomBorder from '../images/bottom-border.svg'
import { textShadowValue } from '../utils/constant';


export const TutorialStyleOptions = {
    arrowColor: '#080808',
    backgroundColor: '#080808',
    overlayColor: '#000000e1',
    primaryColor: '#ACFF01',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    zIndex: 50,
    width:"100%",
    textColor: "#ACFF01"
}


export const joinGameContent = () => {
    return (                    
        <Box
        position="relative"
        p={4}
        overflow="hidden"  // Ensure that pseudo-elements do not overflow the box
        h="100%"
        w={{base: "300px", md: " 500px"}}  // Set width
        color="brand.100"
      >
        <Flex direction="column" >
          <Box
            as="span"
            position="absolute"
            top="0"
            left="0"
            height="45px"  // Adjust size as needed
            width="45px"
            bgImage={`url(${TopBorder})`} 
            backgroundRepeat="no-repeat"
            bgSize="cover"
          />
          <Box ml="5%" textAlign="left">

              <Text fontWeight="semibold" fontSize={{base: "sm", md: "lg"}}  color="brand.500" 
                textShadow={textShadowValue} mt={6}
                textAlign="left"
              >
                How to Play
              </Text>
              {/* subtitle, xl = 1.25rem, 1rem=16px */}
              <Text  fontSize={{base: "xs", md: "md"}}  color="brand.100" mt={6}
              
              >
                Start playing and secure your place in the queue now!

              </Text>
            <List spacing={3} mt={6}>
                <ListItem fontSize={{base: "xs", md: "sm"}}  color="brand.700">
                    <ListIcon as={FaEthereum} color='brand.700' />
                    Minimum Bet: {entryFee} ETH per position.
                </ListItem>
                <ListItem fontSize={{base: "xs", md: "sm"}}  color="brand.700">
                    <ListIcon as={FaExchangeAlt} color='brand.700' />
                    Maximum Bet: Up to 100 positions per transaction.
                </ListItem>
                <ListItem fontSize={{base: "xs", md: "sm"}}  color="brand.700">
                    <ListIcon as={FaRegListAlt} color='brand.700' />
                    Rules: Each position you buy places you in line. Every three positions joins, the first one in line exits the queue with 200% of the position's bet. 
                </ListItem>
                <ListItem fontSize={{base: "xs", md: "sm"}}  color="brand.700">
                    <ListIcon as={FaRegGem} color='brand.700' />
                    Rewards: When a round ended, if you don’t exit, each position can claim $LONG token as a reward.
                </ListItem>
                <ListItem fontSize={{base: "xs", md: "sm"}}  color="brand.700">
                    <ListIcon as={FaRegClock} color='brand.700' />
                    Game Duration: The game starts as soon as one player joins and ends if no one joins within {gameDurationText}.

                </ListItem>
            </List>

          </Box>
          <Box
            as="span"
            position="absolute"
            right="0"
            bottom="0"
            height="45px"  // Adjust size as needed
            width="45px"
            backgroundImage={`url(${BottomBorder})`}  
            backgroundRepeat="no-repeat"
            bgSize="cover"
          />
          
        </Flex>
   
  
      </Box>
   )
}

export const ActivityTutorialContent = () => {
    return (                    
        <Box
        position="relative"
        p={4}
        overflow="hidden"  // Ensure that pseudo-elements do not overflow the box
        h="100%"
        w={{base: "300px", md: " 500px"}}  // Set width
        color="brand.100"
      >
        <Flex direction="column" >
          <Box
            as="span"
            position="absolute"
            top="0"
            left="0"
            height="45px"  // Adjust size as needed
            width="45px"
            bgImage={`url(${TopBorder})`} 
            backgroundRepeat="no-repeat"
            bgSize="cover"
          />
          <Box ml="5%" textAlign="left">

              <Text fontWeight="semibold" fontSize={{base: "sm", md: "lg"}}  color="brand.500" 
                textShadow={textShadowValue} mt={6}
                textAlign="left"
              >
                Your Activity Overview
              </Text>
              {/* subtitle, xl = 1.25rem, 1rem=16px */}
              <Text  fontSize={{base: "xs", md: "md"}}  color="brand.100" mt={6}
              >
                Stay updated on your game activity and never miss a reward!

              </Text>
            <List spacing={3} mt={6}>
                <ListItem fontSize={{base: "xs", md: "sm"}}  color="brand.700">
                    <ListIcon as={FaRegSmileBeam} color='brand.700' />
                    Game Status: See your active participation and current queue position.
                </ListItem>
                <ListItem fontSize={{base: "xs", md: "sm"}}  color="brand.700">
                    <ListIcon as={FaRegGem} color='brand.700' />
                    Claim $LONG Token: When a round ends and you're eligible, claim your rewards here.
                </ListItem>
                <ListItem fontSize={{base: "xs", md: "sm"}}  color="brand.700">
                    <ListIcon as={FaHistory} color='brand.700' />
                    Previous Rounds: View all past rounds you’ve participated in.
                </ListItem>
            </List>

          </Box>
          <Box
            as="span"
            position="absolute"
            right="0"
            bottom="0"
            height="45px"  // Adjust size as needed
            width="45px"
            backgroundImage={`url(${BottomBorder})`}  
            backgroundRepeat="no-repeat"
            bgSize="cover"
          />
          
        </Flex>
   
  
      </Box>
   )
}



export const ShareTwitterContent = () => {
    return (                    
        <Box
        position="relative"
        p={4}
        overflow="hidden"  // Ensure that pseudo-elements do not overflow the box
        h="100%"
        w={{base: "300px", md: " 500px"}}  // Set width
        color="brand.100"
      >
        <Flex direction="column" >
          <Box
            as="span"
            position="absolute"
            top="0"
            left="0"
            height="45px"  // Adjust size as needed
            width="45px"
            bgImage={`url(${TopBorder})`} 
            backgroundRepeat="no-repeat"
            bgSize="cover"
          />
          <Box ml="5%" textAlign="left">

              <Text fontWeight="semibold" fontSize={{base: "sm", md: "lg"}}  color="brand.500" 
                textShadow={textShadowValue} mt={6}
                textAlign="left"
              >
                Boost Your Odds – Share the Fun!
              </Text>
              {/* subtitle, xl = 1.25rem, 1rem=16px */}
              <Text  fontSize={{base: "xs", md: "md"}}  color="brand.100" mt={6}
              >
                Share on Twitter now and optimize your odds to win big! #LongGame #LONG

              </Text>
            <List spacing={3} mt={6}>
                <ListItem fontSize={{base: "xs", md: "sm"}}  color="brand.700">
                    <ListIcon as={FaAngleDoubleUp} color='brand.700' />
                    Supercharge Your Chances: The more players that join, the higher your chances of exiting with a 200% reward.
                </ListItem>
                <ListItem fontSize={{base: "xs", md: "sm"}}  color="brand.700">
                    <ListIcon as={FaShareAlt} color='brand.700' />
                    Spread the Excitement: Tell your community about Long Game and invite them to join, bringing more thrill and dynamics to the game!
                </ListItem>
                <ListItem fontSize={{base: "xs", md: "sm"}}  color="brand.700">
                    <ListIcon as={FaPeopleGroup} color='brand.700' />
                    Giveaway $LONG Tokens: Help distribute $LONG tokens and grow our vibrant community.
                </ListItem>
            </List>

          </Box>
          <Box
            as="span"
            position="absolute"
            right="0"
            bottom="0"
            height="45px"  // Adjust size as needed
            width="45px"
            backgroundImage={`url(${BottomBorder})`}  
            backgroundRepeat="no-repeat"
            bgSize="cover"
          />
          
        </Flex>
   
  
      </Box>
   )
}