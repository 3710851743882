import React, { useRef } from 'react';
import { Box } from '@chakra-ui/react';


const VideoBackground = ({ src }) => {
    const videoRef = useRef(null);

    const handleMetadataLoaded = () => {
      if (videoRef.current) {
          videoRef.current.playbackRate = 0.5;  // Set playback rate to 0.5
      }
  };
    
  return (
    <Box position="absolute" top="45vh" left="0" width="100%" height={{base:"70%", md:"55vh"}} overflow="hidden" zIndex="-1">
      <video 
        ref={videoRef}
        autoPlay 
        muted 
        loop 
        playsInline 
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        onLoadedMetadata={handleMetadataLoaded}
        >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default VideoBackground;
