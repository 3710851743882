export const TabStyle = {
    variants: {
      enclosed: {
        tab: {
          borderRadius: '0px 0px 0 0',
          border: '1px solid',
          borderColor: 'brand.800',
          borderBottom: 'none',
          _selected: {
            color: 'brand.100',
            bg: 'brand.800',
            borderColor: 'brand.700',
            borderBottom: 'none',
            mb: '-1px',
          },
        },
        tablist: {
          mb: '-1px',
          borderBottom: '1px solid',
          borderColor: 'brand.700',
        },
        tabpanel: {
          border: '1px solid',
          borderColor: 'brand.700',
          borderTop: 'none',
          borderRadius: '0 0 0px 0px',
          p: 6,
        },
      },
    },
  }