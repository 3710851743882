import { Box, HStack, Flex, Text, SimpleGrid, Image, Center, Button } from "@chakra-ui/react";
import BlastLogo from '../images/blast.png'
import Bb1 from '../images/header-bg1.png'
import Dragon from '../images/header-dragon-2x.png'
import DragonBG from '../images/header-dragon-bg.png'
import { AnimatedBox } from "../AnimateComponents/AnimatedBox";
import { textShadowValue } from "../utils/constant";
import VideoBackground from "./VideoBackground";
import VideoBG from '../media/Video_1920x1080.mp4'
import LogoSquare from '../images/long-game-logo-square.svg'
import { Link } from 'react-router-dom';
import { AnimatedBanner } from '../AnimateComponents/AnimatedBanner'
import { DoubleImage } from "../AnimateComponents/DoubleImage";
import { BlastBanner } from "./BlastBanner";


export function Header() {

    return(
            <Flex bgImage={`url(${Bb1})` }  
                width="100%" 
                height={{base: "100%", md:"93vh"}}
                bgPosition="Bottom"
                backgroundRepeat="no-repeat"
                bgSize="contain"
                direction="column"
                zIndex="1"
            >
                <AnimatedBanner />
             <VideoBackground src={VideoBG} />
                <SimpleGrid  columns={{base: 1, md: 2}} mt="5%" mx="5%" >
            
                    <AnimatedBox distance={-300}>
                        <Flex direction="column" 
                            alignItems={{base: "center", md: "flex-start", lg:"flex-start"}}
                            px={{base: 5, md: 20}} mt={{base: "2", md: "40"}}
                            
                            >
                        <Text color="brand.100" 
                            fontWeight="semibold" 
                            fontSize={{base: "3xl",  md: "4xl"}}
                            textShadow={textShadowValue}
                        > 
                            Play, Win, Earn
                        </Text>
                        
                        <Text color="brand.300" 
                            fontSize={{base: "xl", md: "22px"}} fontWeight="semibold" mt={4} 
                            textAlign={{base:"center", md: "left"}}
                        > 
                            Walk away with 200% right away, or Grow rich with Long Game 
                        </Text>
                        
                            <Button  variant="custom" fontSize="sm" height="48px" as={Link} to="/play" 
                                width="200px" 
                                mt={10}
                                mb={20}
                            >
                                PLAY NOW
                            </Button>
                        
                        </Flex>
                    </AnimatedBox>

                    
                    <Flex justifyContent="center" alignItems="center" >
                        <DoubleImage bgImg={DragonBG} fgImg={Dragon} distance={300}/>
                    </Flex>
                    

                </SimpleGrid>
                <BlastBanner />
                </Flex>
            
           
    )
}

