import { Box, HStack, Flex, Text, Image, Center , Button} from "@chakra-ui/react";
import {  motion, AnimatePresence, usePresence } from "framer-motion"
import { formatAvatarUrl } from "../utils/utils";

import { formatAddress } from "../utils/utils";
import { formatDistanceToNow } from 'date-fns';
import { entryFee } from "../utils/constant";
import { useNativeCurrency } from '../providers/NativeCurrencyProvider'; 


export function AnimatedPlayerList({ children, list, totalFetched }) {
    const [isPresent, safeToRemove] = usePresence()
    const nativeCurrencySymbol = useNativeCurrency();
    const transition = { type: 'spring', stiffness: 500, damping: 50, mass: 1, duration: 3 }
    const animations = {
        initial:false,
        layout: true,
        initial: 'out',
        style: {
          position: isPresent ? 'static' : 'absolute'
        },
        animate: isPresent ? 'in' : 'out',
        whileTap: 'tapped',
        variants: {
          in: {  opacity: 1,  scaleY: 1,},
          out: {  opacity: 0, zIndex: -10, scaleY: 1},
          tapped: { scale: 0.98, opacity: 0.5, transition: { duration: 0.1 } }
        },
        onAnimationComplete: () => !isPresent && safeToRemove(),
        transition
    }
  
    return (
        <Box width="inherit">
        <AnimatePresence>
            { list.map((player , index)=> (
            <motion.div layout
                {...animations}
                key={totalFetched - index}
              >
            <Flex direction="row" key={index}   justify="space-between" pt="4">
                <HStack>
                    <Image src={formatAvatarUrl(player.participant)} boxSize="24px" />
                    <Text fontSize={{base: "sm", md: "md"}}  color="brand.100" mr={6}> {formatAddress(player.participant)}</Text>
                </HStack>
                <Text fontSize={{base: "sm", md: "sm"}} color="brand.700">{player.amount} {nativeCurrencySymbol} </Text>
                <Text fontSize={{base: "sm", md: "sm"}} 
                      color="brand.700"
                      width={{base: "80px", md: "160px"}} 
                >
                   {formatDistanceToNow(new Date(Math.floor(player.timestamp*1000)), { addSuffix: true })}
                </Text>
            </Flex>
            </motion.div>
            ))}
            </AnimatePresence>
        </Box>
    );
  }