import React, { createContext, useState, useContext, useEffect } from 'react';
import { createClient, WagmiConfig, useNetwork } from 'wagmi';
import { providers } from 'ethers';

// Create a Context for the native currency symbol
const NativeCurrencyContext = createContext();

// Create a Provider component
const NativeCurrencyProvider = ({ children }) => {
  const { chain } = useNetwork();
  const [nativeCurrencySymbol, setNativeCurrencySymbol] = useState('ETH');

  useEffect(() => {
    if (chain && chain.nativeCurrency) {
      setNativeCurrencySymbol(chain.nativeCurrency.symbol);
    }
  }, [chain]);

  return (
    <NativeCurrencyContext.Provider value={nativeCurrencySymbol}>
      {children}
    </NativeCurrencyContext.Provider>
  );
};

// Custom hook to use the NativeCurrencyContext
const useNativeCurrency = () => useContext(NativeCurrencyContext);



export { NativeCurrencyProvider, useNativeCurrency };
