import React, { useState } from 'react';
import { Box, Flex, Link , Text} from '@chakra-ui/react';
import { motion, useAnimationControls } from 'framer-motion';
import { networkInfo, mLongGameContract } from "../utils/config";
import { formatAddress } from "../utils/utils";
import { ExternalLinkIcon } from '@chakra-ui/icons';

export const AnimatedBanner = () => {
    const [isHovered, setIsHovered] = useState(false);
    const controls = useAnimationControls();
  
    const announcements = [
        <>
         {'  '} mainnet is live
          <Link 
            href={`${networkInfo.chain.explorer}address/${mLongGameContract}#code`} 
            isExternal 
            color="brand.100"
            ml={1}
          >
            {formatAddress(mLongGameContract)} <ExternalLinkIcon mx="2px" />
          </Link>
        </>,
         ' be careful of scams ',
        ' play long game,  long crypto',
        ' invite friends to increase your chance',
        ' Earn $LONG Token'
      ];

    

      const fullText = announcements.map((item, index) => (
        <Text as="span" key={index} px="2"> {/* Added padding here */}
          {index < announcements.length  && '////  '}
          {item}
          
        </Text>
      ));

      React.useEffect(() => {
        const animate = () => {
            controls.start({
              x: '-20%',
              transition: {
                x: {
                  repeat: Infinity,
                  repeatType: "loop",
                  duration: 15,
                  ease: "linear",
                },
              },
            });
          };
      
          if (!isHovered) {
            animate();
          } else {
            controls.stop();
          }
      }, [isHovered, controls]);
    
      return (
        <Box
          bg="brand.800"
          color="brand.500"
          py="10px"
          overflow="hidden"
          fontSize={{base:"xs"}}
          lineHeight="12px"
          fontWeight="semibold"
      
          textTransform="uppercase"
          userSelect="none"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <motion.div
            animate={controls}
            style={{
              display: 'flex',
              width: 'max-content',
            }}
          >
            {[...Array(6)].map((_, index) => (
              <Flex key={index} flexShrink={0} alignItems="center">
                {fullText}
              </Flex>
            ))}
          </motion.div>
        </Box>
      );
    };