import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import GemIcon from "../images/gems.png"

export const RaindropAnimation = () => {
  const containerRef = useRef();

  useEffect(() => {
    const raindrops = Array.from({ length: 20 }).map(() => {
      const div = document.createElement('div');
      div.style.width = '36px'; // Narrower raindrops
      div.style.height = '36px'; // Longer raindrops
      //div.style.backgroundColor = '#' + Math.floor(Math.random()*16777215).toString(16);
      div.style.backgroundImage = `url(${GemIcon})`
      div.style.backgroundSize = 'cover';
      div.style.backgroundRepeat = 'no-repeat';
      div.style.position = 'absolute';
      div.style.zIndex = "999"
      containerRef.current.appendChild(div);
      return div;
    });

    raindrops.forEach((raindrop) => {
      gsap.fromTo(raindrop, {
        y: () => Math.random() * -200, // Start just above the view to make it look seamless
        x: () => Math.random() * (window.innerWidth -50) // Spread them across the width
      }, {
        y: window.innerHeight, // Move to the bottom of the view
        x: () => `+=${Math.random() * 40 - 10}`, // Slight horizontal movement
        opacity: 1, // Keeping them slightly transparent
        duration: 3 + Math.random(), // Randomize duration for variation
        ease: 'none', // Linear movement for constant raindrop falling speed
        onComplete: () => raindrop.remove(),
       
      });
    });
  }, []);

  return <div ref={containerRef} style={{ position: 'abusolute',  overflow:"hidden"}} />;
};

