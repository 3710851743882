import { Box, Flex, Text, Center } from "@chakra-ui/react";
import { useQuery, gql } from '@apollo/client';
import { AnimatedPlayerList } from "../AnimateComponents/AnimatedPlayerList";
import { useState, useEffect } from 'react'
import { entryFee } from "../utils/constant";
import { graphQLClient } from './apollo-client';

/** Return data example
 {
    "joinCount": 4,
    "joinedAt": 1715129712,
    "id": 3,
    "participant": "0x4F96AB31bbbdb65FBac48Bca54304BF64caDdaCD"
  }
 * 
 */
const GET_INITIAL_DATA = gql`
  query JoinerQuery {
    referrals(orderBy: "joinedAt", orderDirection: "desc", limit: 8) {
        items {
          participant
          joinCount
          joinedAt
        }
      }
  }
`;

const GET_FETCH_DATA = gql`
  query JoinerMoreQuery($ts: Int!) {
    referrals(orderBy: "joinedAt", orderDirection: "desc", limit: 8, where: {joinedAt_gte: $ts}) {
        items {
          participant
          joinCount
          joinedAt
        }
      }
  }
`;

const addedList = [
    {participant: "0xada2ddac4518d853EffaA1b994BddC0cF02", joinCount: 4, joinedAt:1715584248 },
]

const mockList = [
    {participant: "0x1fc46C05deccc4518d853EffaA1b994BddC0cF02", joinCount: 5, joinedAt:1715581248 },
    {participant: "0x2fc46C05deccc4518d853EffaA1b994BddC0cF02", joinCount: 5, joinedAt:1715581248 },
    {participant: "0x3fc46C05deccc4518d853EffaA1b994BddC0cF02", joinCount: 5, joinedAt:1715581248 },
    {participant: "0x4fc46C05deccc4518d853EffaA1b994BddC0cF02", joinCount: 5, joinedAt:1715581248 },
    {participant: "0x5fc46C05deccc4518d853EffaA1b994BddC0cF02", joinCount: 5, joinedAt:1715581248 },
    {participant: "0x6fc46C05deccc4518d853EffaA1b994BddC0cF02", joinCount: 5, joinedAt:1715581248 },
    {participant: "0x7fc46C05deccc4518d853EffaA1b994BddC0cF02", joinCount: 5, joinedAt:1715581248 },
    {participant: "0x8fc46C05deccc4518d853EffaA1b994BddC0cF02", joinCount: 5, joinedAt:1715581248 },
    
]

export function RecentJoiners() {
    const [fetchSince, setFetchSince] = useState(Math.floor(new Date()/1000))
    const [joinerList, setjoinerList] = useState([])

    const [totalFetched, setTotalFetched] = useState(0)

    //testing state
    const [testTotalFetched, setTestTotalFetched] = useState(mockList.length)
    const [testList, setTestList] = useState(mockList)

    const {loading, error, data} = useQuery(GET_INITIAL_DATA, {client: graphQLClient})
    const pollingResults = useQuery(GET_FETCH_DATA, {
        variables: { ts: fetchSince },
        pollInterval: 15000 , // Poll every 15 seconds
        client: graphQLClient,
      })

    useEffect(() => {
        if (data && data.referrals && data.referrals.items && data.referrals.items.length > 0 ) {
          setFetchSince(Math.floor(new Date()/1000))
          setjoinerList(transformList(data.referrals.items))
          setTotalFetched(data.referrals.items.length)
        }
      }, [data]);

    useEffect(() => {
        if (pollingResults && pollingResults.data && pollingResults.data.referrals && pollingResults.data.referrals.items.length > 0 ) {
          const newJoiners = transformList(pollingResults.data.referrals.items)
          const mergedJoined = [...newJoiners , ...joinerList].slice(0,8)

          
          setFetchSince(Math.floor(new Date()/1000))
          setjoinerList(mergedJoined)
          setTotalFetched(totalFetched + newJoiners.length)
        }
      }, [pollingResults]);

    const testAddMock = () => {
            setTestList(prev => [ ...addedList, ...prev ].slice(0,8))
        
        setTestTotalFetched(testTotalFetched + addedList.length)
    }
    const transformList = (list) => {
      return list.map( l => ({
        participant: l.participant,
        timestamp: l.joinedAt,
        amount: l.joinCount * entryFee,
      }))
    }

    return(
        <Box>
            {loading && <Text color="brand.700">Loading...</Text>}
            {error && <Text color="brand.700">Error...</Text>}
            { joinerList? 
             <Flex direction="column" justify="flex-start" mt={3}> 
               < AnimatedPlayerList totalFetched={totalFetched} list={joinerList} />
            </Flex>
                :
            
            <Center>
                <Text fontSize={{base: "xs", md: "md"}} color="brand.700" fontWeight="semibold" mt={6}>
                    No Activities
                </Text>
            </Center>
            }
        </Box>
    )
}

