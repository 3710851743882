import {  HStack, Text, Box, Image } from "@chakra-ui/react";
import React, {  useState, useRef } from 'react';

import { textShadowValue } from '../utils/constant'

import BlastLogo from '../images/blast.png'
export function BlastBanner() {

    return (
        <Box >
        <HStack mb={10} px={[5, 10,40]}  >
            <Text as="span" color="brand.500" fontSize={["sm","lg"]} fontWeight="semibold" textShadow={textShadowValue}>
                BUILD ON
            </Text>
            {/**
            * <Image disply="in-line" src={LogoSquare} boxSize="16px" objectFit='cover' ml={1}></Image>
            */}
            <Image  display="inline"
                    height="18px"
                    objectFit='cover'
                    src={BlastLogo}
                    alt='Blast'>
            </Image> 
        </HStack>
    </Box>
    )
}
