import { Box, Link, Flex, Text, SimpleGrid, useMediaQuery, useDisclosure, Progress, Center } from "@chakra-ui/react";
import {motion, useAnimation} from "framer-motion"
import React, { useEffect, useState, useRef } from 'react';
import { InfoOutlineIcon, CheckCircleIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons'
import { AlertStatus } from '../utils/constant'

export function AnimatedAlert({message, onAlertExit, url, alertType}) {

    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
    const styleConfig={
        base:{
            position: "fixed", bottom: "20px", 
            left: "50%", 
            transform: "translateX(-50%)", 
            width: "95%", 
            zIndex: 1000, 
            backgroundColor: "black"
        },
        md: {
            position: "relative", top: 0 
        }
    }

    const dropIn = {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 2,
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 2,
            }
        }
    }
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const controls = useAnimation();

    const durationValue = url? 12000 : 8000

    useEffect(() => {
          let timer;
          if (isOpen) {
              onOpen();
              controls.start({ width: "0%", opacity: 1 });
              timer = setTimeout(() => {
                  onClose();
                  onAlertExit();
                  controls.start({ width: "0%" , opacity: 0, transition: { duration: durationValue}});
              }, durationValue);
          }
          return () => {
            clearTimeout(timer);
          }
      }, [isOpen, onOpen, onClose, controls]);


    const showMessage = () => {
        if(url) {
            return (<Text fontSize={{base:"sm", md:"md"}} color="brand.500" fontWeight="semibold">
                {message} 
                    <>
                    <Link  href={url} isExternal color="brand.1000">
                        {' -> '} See transaction on explorer
                    </Link>
                    </>
                </Text>)
        } else {
            return  (<Text fontSize={{base:"sm", md:"md"}} color="brand.500" fontWeight="semibold">
            {message} 
            </Text>
            )
        }
    }

    const showAlertTypeIcon = () => {
        if(alertType === AlertStatus.SUCCESS) {
            return (<CheckCircleIcon color="brand.100" mr={4} boxSize="24px" />)
        } else if(alertType === AlertStatus.WARNING) {
            return (<WarningIcon color="brand.500" mr={4} boxSize="24px" />)
        } else if(alertType === AlertStatus.ERROR) {
            return (<WarningTwoIcon color="brand.900" mr={4} boxSize="24px" />)
        } else {
            return (<InfoOutlineIcon color="brand.500" mr={4} boxSize="24px" />)
        }
    }

    return (
        isOpen ? (
        <motion.div style={styleConfig.base}
            initial="hidden"
            animate="visible"
            variants={dropIn}
            exit="exit"
          
        >
            <Center width="100%" height="52px" border="1px" borderColor="brand.700">
                {showAlertTypeIcon()}
                {showMessage()}
            </Center>
            <motion.div initial={{ width: "100%" }} animate={controls} transition={{ duration: 8 }}>
                <Progress bg="brand.100" size="xs" isAnimated  />
            </motion.div>
        </motion.div>
        ): null
    )

}
