import { Box, HStack, Flex, Text, SimpleGrid, Image, Center, Button } from "@chakra-ui/react";


import { AnimatedBox } from "./AnimatedBox";
import {
    motion,
   
  } from "framer-motion";

export function DoubleImage({bgImg, fgImg, distance}) {
    const upVariants = {
        hover: {
            x: "-2%", y: "-10px",
            transition: { type: 'spring', stiffness: 300 },
      
        },
        initial: {
            y: 0
        }
    };

    // Animation variants for moving down
    const downVariants = {
        hover: {
            x: "2%", y: "10px",
            
            transition: { type: 'spring', stiffness: 300 }
        },
        initial: {
            y: 0
        }
    };
    return(
        <Box position="relative" 
            height="fit-content"  
            width={{base: "70vw",  md: "35vw", xl: "30vw"}} 
        >

            <AnimatedBox distance={distance}> 
            <motion.div whileHover="hover">
            
                <motion.img src={bgImg}
                
                style={{ 
                    position:"absolute",
                    top:"-3%",
                    left:"-1%",
                    width:"90%",
                    objectFit:'contain', 
                    zIndex:"0"

                }}
                variants={downVariants}
                alt='header-dragon-bg' 
                
                    />
                <motion.img 
                    src={fgImg} 
                    style={{
                        objectFit:'contain',
                        position:"relative",
                        width:'90%',
                        right:"-5%",
                        margin: '15px',
                        zIndex: "docked"
                    }}
                    alt='header-dragon' 
                    variants={upVariants}
                    
                />
                </motion.div>
            </AnimatedBox>
        </Box>

            
           
    )
}

