import { useRef } from "react";
import {  motion, useInView } from "framer-motion"

export function AnimatedBox({ children, distance }) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const variants = {
        none: { opacity:0, x: distance},
        inView: {
            opacity:1, x:0,
        }
    }
  
    return (
      <motion.div ref={ref}  initial='none' style={{ overflow: 'hidden'}}
      transition={{
        duration: 2,
        x: { duration: 1 }, 
        ease: "linear", 
        }}
      variants={variants}
      animate={isInView? 'inView': 'none'}>
       
          {children}
       
      </motion.div>
    );
  }