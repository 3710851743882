import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const graphQLUri = process.env.REACT_APP_GRAPHQL_URI;

const backendUri = process.env.REACT_APP_BACKEND_URI;
console.log('graphQLUri=', graphQLUri)
console.log('backendURI=', backendUri)

const graphQLClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: graphQLUri, 
  })
});

const backendClinet = new ApolloClient({
  link: new HttpLink({
    uri: backendUri,
  }),
  cache: new InMemoryCache()
});

export { graphQLClient, backendClinet };