import { Box} from "@chakra-ui/react";
import { useTime, useAnimation, motion } from "framer-motion"
import { useState, useEffect } from 'react'

export function AnimatedProgress({value}) {
    const maxWidth = 100
    function progressIndicator(value, maxWidth) {
        // value should between 0 - 100
        return Math.floor(maxWidth * (value / 100))
    }
    const len = progressIndicator(value, maxWidth )
    // Set up the animation controls
    const animations = {
        initial: {width: "0px"},
        animate: {width: len, transition:{duration: 5} }
    }

    return (
        <Box width={(maxWidth.toString() + "px")} borderRadius="8px" height="8px" bg="brand.700" >
            <motion.div 
                {...animations}
                style={{
                    height: '8px',
                    borderRadius: '8px',
                    backgroundColor: '#ACFF01'
                }}
            >
            </motion.div>
        </Box>
    )
}