import React from 'react';
import { motion } from 'framer-motion';

export const AnimationBouncing = ({children}) => {
  return (
    <motion.div
      animate={{
        y: ["0%", "10%", "0%"]  // Keyframes for bouncing up and down
      }}
      transition={{
        y: {
          duration: 2,   // Duration of one bounce cycle
          ease: "easeInOut", // Ease function for a more natural bounce
          repeat: Infinity, // Repeat indefinitely
          repeatType: "loop"
        }
      }}
    >
      {children}
    </motion.div>
  );
};
