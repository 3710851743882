import {
  networkInfo, 
  longGameContract, 
  LongGameABI,
  MultipleLongGameABI,
  mLongGameContract
} from './config.js'

import { ethers } from 'ethers'

export function getContract(t){
  try{
    if(t === 'provider') {
      
      if (typeof window.ethereum !== 'undefined') {
          // window.ethereum is available, initialize the provider
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(longGameContract, LongGameABI.abi, provider)
          console.log('Contract: Connected to Web3 provider')
              return {provider, contract }
      
          
        } else {
          // read only use the rpcProvider
          const provider = new ethers.providers.JsonRpcProvider(networkInfo.chain.rpcUrls.default.http[0]);
          const contract = new ethers.Contract(longGameContract, LongGameABI.abi, provider)
          
          console.log('Contract: Connected to RPC provider')
          return {provider, contract }
        }
      }
      
  
    if(t === 'signer'){
        if (typeof window.ethereum !== 'undefined') {
            // window.ethereum is available, initialize the provider
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner()
            const contract = new ethers.Contract(longGameContract, LongGameABI.abi, signer)
            return {provider, contract }
          } else {
            console.error('Please connect wallet to get your stats');
            return {undefined, undefined}
          }
      } 

    if(t === "read") {
      const provider = new ethers.providers.JsonRpcProvider(networkInfo.chain.rpcUrls.default.http[0]);
      const contract = new ethers.Contract(longGameContract, LongGameABI.abi, provider)
      return {provider, contract }
    }

  }catch(error){
    console.error(error)
  }

}

// only used for 'signer' user case
export function getMultiplContract(){
  try{
    if (typeof window.ethereum !== 'undefined') {
        // window.ethereum is available, initialize the provider
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner()
        const contract = new ethers.Contract(mLongGameContract, MultipleLongGameABI.abi, signer)
        return contract
      } else {
        console.error('Please connect wallet to play Long Game');
        return undefined
      }
  }catch(error){
    console.error(error)
  }

}